import { useEffect, useState } from "react";
import { InfermiereServizio } from "../models/InfermiereServizio";
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ApiInfermieriServizi from '../api/InfermieriServizi';
import ApiInfermieri from '../api/Infermieri';
import Cookies from "js-cookie";
import GiornoCalendarioUtils from "../utils/GiornoCalendarioUtils";
import DialogServizioInfermiere from "../components/servizi/DialogServizioInfermiere";
import ButtonMenu from "../components/generics/ButtonMenu";
import '../style/stylePages/Servizi.css';
import { ToastContainer, toast } from "react-toastify";
import LavoraDate from "../utils/LavoraDate";
import LoadingView from "../components/generics/LoadingView";
import { useLocation, useNavigate } from "react-router-dom";
import { InfermiereInfo } from "../models/InfermiereInfo";
import Utenti from "../api/Utenti";


function Servizi() {
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta servizio
    const [utente, setUtente] = useState<any>();
    const [servizi, setServizi] = useState<InfermiereServizio[]>();
    const [servizioSelezionato, setServizioSelezionato] = useState<InfermiereServizio>();

    const [rowsPerPage, setRowsPerPage] = useState(10);  //Righe per pagina: Valore statico deciso qui!!!
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const [infermiere, setInfermiere] = useState<InfermiereInfo>();
    const [isAdmin, setIsAdmin] = useState(false);

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);
    const navigate = useNavigate();


    const getServizi = async (pagina: number = 1) => {
        setLoading(true);
        if (utente) {
            const data = await ApiInfermieriServizi.getElencoServiziInfermiere(utente._id, pagina, rowsPerPage);
            if(data) {
                setServizi(data.docs);
                setTotalPages(data.totalPages);
            }
        }
        setLoading(false);
    }

    const getInfermiere = async (idInfermiere: string) => {
        const inf = await ApiInfermieri.getSingoloInfermiere(idInfermiere);
        setInfermiere(inf);
    }

    const deleteServizio = async (infermiereServizio: InfermiereServizio) => {
        try {
            const data = await ApiInfermieriServizi.deleteServizioInfermiere(infermiereServizio);
            setServizioSelezionato(undefined);
            setServizi(undefined);
            toast.success("Servizio cancellato con successo");
        } catch (err: any) {
            toast.error(err);
        }
        
    }

    const updateServizio = async (servizio: InfermiereServizio) => {
        try {
            await ApiInfermieriServizi.updateServizioInfermiere(servizio);
            toast.success('Servizio aggiornato con successo!');
        } catch(err: any) {
            toast.error(err);
        }
    }

    const addServizio = async (servizio: InfermiereServizio) => {
        if(isAdmin)
            servizio.idInfermiere = infermiere?.idUtente;

        try {
            await ApiInfermieriServizi.addServizioInfermiere(servizio);
            toast.success('Servizio aggiunto con successo!');
        } catch(err: any) {
            toast.error(err);
        }
    }

    const openDialogFunc = (infermiereServizio?: InfermiereServizio) => {
        if(infermiereServizio)
            setServizioSelezionato(infermiereServizio);
        
        setOpenDialog(true);
    }

    const closeDialogAndRefresh = () => {
        setServizi(undefined);
        setServizioSelezionato(undefined);
        setOpenDialog(false);
    }

    const closeDialog = () => {
        setServizioSelezionato(undefined);
        setOpenDialog(false);
    }

    const checkAndSetInfermiere = async (id: string) => {
        const usr = await Utenti.getMe();
        if(usr.ruolo !== 'admin')
            navigate('/');
        else {
            setUtente({_id: id});
            getInfermiere(id);
        }
    }

    useEffect(() => {
        if(!utente) {
            const id = queryParameters.get("id");
            if(id) {
                setIsAdmin(true);
                checkAndSetInfermiere(id);
            }
            else {
                let authCookie = Cookies.get('auth');
                let user = {}
                if(authCookie) {
                    let ck = JSON.parse(authCookie);
                    user = ck.user;
                    setUtente(user);
                }
            }
        }
        else if(!servizi) {
            getServizi();
        }
    });


    //Paginazione
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
        getServizi(newPage);
    }



    
    return (
        <div className="page-template">
            <div className='main-content' style={{ visibility: servizi ? 'visible': 'hidden'}}>
                <h2>{isAdmin? `Elenco servizi di ${infermiere?.nome} ${infermiere?.cognome}` : 'I tuoi servizi'}</h2>
                <a onClick={() => navigate(-1)} style={{display: isAdmin? 'block' : 'none'}}>Indietro</a>

                <Button variant="contained" onClick={() => openDialogFunc()}>Aggiungi servizio</Button>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className="tabella-servizi" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Nome sevizio</TableCell>
                            <TableCell>Tariffa</TableCell>
                            <TableCell>Durata</TableCell>
                            <TableCell>Note</TableCell>
                            <TableCell>Giorni No</TableCell>
                            <TableCell>Fascia Oraria No</TableCell>
                            <TableCell>Stato</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {servizi?.map((row: InfermiereServizio) => (
                            <TableRow
                                hover
                                key={row._id}
                                className="table-row"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}>{row.nomeServizio}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}>{`${row.prezzo?.toFixed(2)} €`}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}>{row.durata && `${row.durata*15} minuti`}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}>{row.noteAppuntamento}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}>
                                {row?.giorniNo?.map((day: number) => (
                                    `${GiornoCalendarioUtils.convertiGiorno(day)} `
                                ))}
                                </TableCell>
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}> {
                                    row?.fasciaOrariaNo && row?.fasciaOrariaNo.orarioInizio && row?.fasciaOrariaNo.orarioFine &&
                                    `${LavoraDate.prendeOrario(row.fasciaOrariaNo.orarioInizio)} - 
                                     ${LavoraDate.prendeOrario(row.fasciaOrariaNo.orarioFine)}`}
                                </TableCell>
                                <TableCell onClick={() => openDialogFunc(row)/*handleClick(event, row.name)*/}>{row.attivo ? "Attivo" : "Non attivo"}</TableCell>
                                <TableCell>
                                    <ButtonMenu object={row} onEdit={openDialogFunc} onDelete={deleteServizio} onClose={closeDialogAndRefresh} />
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack spacing={2}>
                    <Pagination className="paginazione" count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
                </Stack>
            </div>
            <DialogServizioInfermiere open={openDialog} addServizio={addServizio} updateServizio={updateServizio} 
                onClose={closeDialog} onSave={closeDialogAndRefresh} servizio={servizioSelezionato} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <LoadingView visible={loading}/>
        </div>

    );
}

export default Servizi;