import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { InfermiereInfo } from "../models/InfermiereInfo";
import { Fattura } from "../models/Fattura";

export default class CreaPDF {
    //Utility per la conversione della data
    static creaDate = (dataStringa: string) => {
        const mesi: { [key: string]: number } = {
        gennaio: 0, febbraio: 1, marzo: 2, aprile: 3, maggio: 4, giugno: 5,
        luglio: 6, agosto: 7, settembre: 8, ottobre: 9, novembre: 10, dicembre: 11
        };
    
        const parti = dataStringa.split(' ');
        if (parti.length !== 3) {
        throw new Error('Formato della data non valido');
        }
    
        const giorno = parseInt(parti[0], 10);
        const mese = mesi[parti[1]];
        const anno = parseInt(parti[2], 10);
    
        if (isNaN(giorno) || mese === undefined || isNaN(anno)) {
        throw new Error('Formato della data non valido');
        }
    
        return new Date(anno, mese, giorno);
    }
    
    static creaFatturaPdf = (fattura: Fattura) => {

        //Aggiungo iva al prezzo dovuto a Futura:
        let totale = 0;
        if (fattura.contabilita && fattura.contabilita.prezzoFutura) {
            totale = fattura.contabilita.prezzoFutura * 0.22;
            totale = fattura.contabilita.prezzoFutura + totale;
        }

        const styles = `
                <style>
                .fattura-container {
                    
                }
                h1 {
                    font-size: 30px !important;
                    font-weight: 900 !important;
                    margin-right: 10px !important; 
                }
                h3 {
                    font-size: 25px !important;
                    font-weight: 600 !important;
                }
                h4 {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    margin-bottom: 10px !important;
                }
                h5 {
                    font-size: 20px !important;
                    font-weight: 400 !important;
                    font-style: italic;
                }
                p {
                    font-size: 16px !important;
                    font-weight: 400 !important;
                    margin-bottom: 2px;
                }
                .fattura-container {
                    font-family: Arial; 
                    padding: 35px 50px; 
                    width: 100%;
                }
                .riga {
                    margin-bottom: 20px;
                }
                .stessa-riga {
                    display: flex; 
                    justify-content: space-between;
                    line-height: 22px !important;
                }
            </style>
        `;
        const htmlContent = `
            ${styles}
            <div class="fattura-container">
                <div class="riga" style="display: flex; align-items: baseline;">
                    <h1>Fattura n.${fattura.numero}</h1> 
                </div>
                <div><p><strong>${fattura!.terminiPagamento!.rifTerminiPagamento}</strong></p></div>

                <div class="riga" style="margin-top: 75px">
                    <p><strong>${fattura!.datiEmittente!.nome} - C.F.: ${fattura!.datiEmittente!.codiceFiscale} - P.IVA: ${fattura!.datiEmittente!.partitaIva}</strong></p>
                    <p>Sede: ${fattura!.datiEmittente!.indirizzo}</p>
                    <p>Regime fiscale: ordinario</p>
                    <p>${fattura!.datiEmittente!.tel} - ${fattura!.datiEmittente!.email}</p>
                </div>
                <div class="riga" style="margin-top: 45px">
                <p><strong>Cessionario/committente</strong></p>
                    <p><strong>${fattura!.infermiere!.ragioneSociale} - CF: ${fattura!.infermiere!.codiceFiscale} - P.IVA: ${fattura!.infermiere!.partitaIva}</strong></p>
                    <p>Sede: ${fattura!.infermiere!.indirizzo}, ${fattura!.infermiere!.cap} ${fattura!.infermiere!.citta}(${fattura!.infermiere!.provincia})</p>
                    <p>SDI: ${fattura!.infermiere!.sdi}</p>
                </div>

                <div class="stessa-riga" style="margin-top: 55px">
                    <div style="width: 50%">
                        <h5>Descrizione</h5>
                        <p><strong>Corrispettivo per servizi di prenotazione come da convenzione - Mese ${fattura.mesePeriodoRiferimento} ${fattura.annoPeriodoRiferimento}</strong></p>
                    </div>
                    <div>
                        <h5>Importo</h5>
                        <h4>${fattura!.contabilita!.prezzoFutura!.toFixed(2)} €</h4>
                    </div>
                    <div>
                        <h5>IVA</h5>
                        <h4>22%</h4>
                    </div>
                    <div>
                        <h5>Totale</h5>
                        <h4>${totale.toFixed(2)} €</h4>
                    </div>
                </div>

                <div style="margin-top: 75px">
                    <h5>Termini di pagamento</h5>
                </div>
                <div style="margin-top: 15px">
                    <p><strong>Modalità</strong>: ${fattura.terminiPagamento!.modalita}<p>
                </div>
                <div style="margin-top: 5px">
                    <p><strong>IBAN</strong>: IT44S0301503200000003825096<p>
                </div>
                <div style="margin-top: 5px">
                    <p><strong>Rif. termini pagamento</strong>: ${fattura!.terminiPagamento!.rifTerminiPagamento}<p>
                </div>
                <div style="margin-top: 5px">
                    <p><strong>Scadenza pagamento</strong>: ${fattura!.terminiPagamento!.scadenzaPagamento}<p>
                </div>
            </div>
        `;
        // Crea un elemento temporaneo off-screen
        const tempEl = document.createElement('div');
        tempEl.style.position = 'absolute';
        tempEl.style.left = '-9999px';
        tempEl.style.width = '210mm'; // Larghezza A4
        tempEl.innerHTML = htmlContent;
        document.body.appendChild(tempEl);

        html2canvas(tempEl, {scale: 3})
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');  // Imposta la dimensione del documento come A4
            const width = pdf.internal.pageSize.getWidth();
            //const height = pdf.internal.pageSize.getHeight();            
            pdf.addImage(imgData, 'JPEG', 0, 0, width, 0);
            pdf.save("fattura.pdf");
        });
    };
}