import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from "react";
import LoadingView from "../generics/LoadingView";
import { InfermiereServizio } from "../../models/InfermiereServizio";
import { Servizio } from "../../models/Servizio";
import ApiServizi from "../../api/Servizi";
import ApiInfermieriServizi from "../../api/InfermieriServizi";
import { cloneDeep, set } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import GiornoCalendarioUtils from "../../utils/GiornoCalendarioUtils";
import '../../style/styleComponents/DialogServizioInfermiere.css';
import LavoraDate from "../../utils/LavoraDate";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

interface Giorno {
    nome: string;
    val: number
}

interface ComponentProps {
    open?: boolean;
    updateServizio(servizio: InfermiereServizio): void;
    addServizio(servizio: InfermiereServizio): void;
    onClose?(open:boolean): void;
    onSave?(open:boolean): void;
    servizio?: InfermiereServizio;
}
export type Props = ComponentProps;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  

function BootstrapDialogTitle(props: DialogTitleProps) {
const { children, onClose, ...other } = props;


return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
        </IconButton>
    ) : null}
    </DialogTitle>
);
}

function DialogcalendarioInfermiere(props: Props) {

    const giorni = GiornoCalendarioUtils.creaElencoGiorni([0,1,2,3,4,5,6]);
    const elencoOrari = GiornoCalendarioUtils.creaElencoOrari();

    const [loading, setLoading] = useState(false);
    const [infermiereServizioSelezionato, setInfermiereServizioSelezionato] = useState<InfermiereServizio>();
    const [elencoServizi, setElencoServizi] = useState<Servizio[]>([]);
    const [servizioSelezionato, setServizioSelezionato] = useState<Servizio>();
    const [editMode, setEditMode] = useState(false);
    const [giorniSelezionati, setGiorniSelezionati] = useState<Giorno[]>([]);
    const [orarioInizio, setOrarioInizio] = useState<string | null>();
    const [orarioFine, setOrarioFine] = useState<string | null>();
    

    const handleElencoGiorni = (gg: Giorno[] | null) => {
        if(gg !== null) {
            const serv = cloneDeep(infermiereServizioSelezionato);
             if(serv) {
                const res = [];
                for(let i = 0; i < gg.length; i++) {
                    res.push(gg[i].val);
                }
                set(serv, 'giorniNo', res);
                setInfermiereServizioSelezionato(serv);
            }
        }
        setGiorniSelezionati(gg!);
    }

    const handleOrario = (field?: string, orario?: string | null) => {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const serv = cloneDeep(infermiereServizioSelezionato);
        if(serv && field) {
            let date;
            if(orario) {
                date = dayjs().tz("Europe/Rome");;
                const hours = parseInt(orario.split(':')[0]);
                const minutes = parseInt(orario.split(':')[1]);
                date = date.hour(hours).minute(minutes);
            }
            set(serv, field, date);
            setInfermiereServizioSelezionato(serv);
            if(field === "fasciaOrariaNo.orarioInizio") {
                setOrarioInizio(orario)
            } else {
                setOrarioFine(orario)
            }
        }
    }
    
    const getElencoServizi = async () => {
        const elenco = await ApiServizi.getElencoServiziAttivi();
        setElencoServizi(elenco.docs);
    }

    const getServizio = async (servizioInf: InfermiereServizio) => {
        const serv = await ApiServizi.getSingoloServizio(servizioInf.idServizio!);
        setServizioSelezionato(serv);
    }

    const handleElencoServizi = (value: Servizio | null) => {
        const serv = cloneDeep(infermiereServizioSelezionato);
        if(serv) {
          set(serv, 'idServizio', value?._id);
          set(serv, 'tipologiaServizio', value?.tipologia)
          setInfermiereServizioSelezionato(serv);
          setServizioSelezionato(value!);
        }
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const serv = cloneDeep(infermiereServizioSelezionato);
        if(serv) {
            let value : any = event.target.value;
            if(field === "durata")
                value = parseFloat(value) / 15;
            if(field === "prezzo") 
                value = parseFloat(value);
            
            set(serv, field, value);
            setInfermiereServizioSelezionato(serv);
        }
    };

    const handleBlur = () => {
        const serv = cloneDeep(infermiereServizioSelezionato);

        const min = servizioSelezionato?.prezzoMin || 0;
        const max = servizioSelezionato?.prezzoMax || 9999;
        let correctedValueString = (infermiereServizioSelezionato?.prezzo || 0).toFixed(2);
        let correctedValue = parseFloat(correctedValueString);
        let prezzo = parseFloat(correctedValueString);

        if ( prezzo < min) {
            correctedValue = min;
        } else if (prezzo > max) {
            correctedValue = max;
        }

      
        if (correctedValue !== infermiereServizioSelezionato?.prezzo) {
            // Aggiorna lo stato con il valore corretto
            if(serv) {
                set(serv, "prezzo", correctedValue);
                setInfermiereServizioSelezionato(serv);
            }
        }
      };
    
    const handleClose = () => {
        setInfermiereServizioSelezionato(undefined);
        setServizioSelezionato(undefined);
        setOrarioInizio(undefined);
        setOrarioFine(undefined);
        setGiorniSelezionati([]);
        props.onClose!(false);
    };

    const increment = () => {
        const serv = cloneDeep(infermiereServizioSelezionato);
        if(serv) {
            let value = serv.durata! + 1;
            set(serv, "durata", value);
            setInfermiereServizioSelezionato(serv);
        }
    };

    const decrement = () => {
        const serv = cloneDeep(infermiereServizioSelezionato);
        if(serv) {
            let value = serv.durata!;
            if(value > 1) value--;
            set(serv, "durata", value);
            setInfermiereServizioSelezionato(serv);
        }
    };


    const handleSalvaButton = async () => {
        setLoading(true);
        if(infermiereServizioSelezionato) {
            if(editMode) {
                props.updateServizio(infermiereServizioSelezionato);
            } else {
                props.addServizio(infermiereServizioSelezionato);
            }
        }
        setInfermiereServizioSelezionato(undefined);
        setServizioSelezionato(undefined);
        setOrarioInizio(undefined);
        setOrarioFine(undefined);
        setGiorniSelezionati([]);
        props.onSave!(false);
        setLoading(false);
    }

    useEffect(() => {
        if(!infermiereServizioSelezionato) {
            if(props.servizio) {
                setInfermiereServizioSelezionato(props.servizio);
                getServizio(props.servizio);
                setGiorniSelezionati(GiornoCalendarioUtils.creaElencoGiorni(props.servizio.giorniNo))
                const inizio = props.servizio.fasciaOrariaNo?.orarioInizio ? new Date(props.servizio.fasciaOrariaNo?.orarioInizio) : undefined;
                const fine = props.servizio.fasciaOrariaNo?.orarioFine ? new Date(props.servizio.fasciaOrariaNo?.orarioFine) : undefined;
                setOrarioInizio(inizio ? `${LavoraDate.prendeOrario(inizio)}` : null);
                setOrarioFine(fine ? `${LavoraDate.prendeOrario(fine)}` : null);
                setEditMode(true);
            }
            else {
                let authCookie = Cookies.get('auth');
                if(authCookie) {
                    let ck = JSON.parse(authCookie);
                    setInfermiereServizioSelezionato({idInfermiere: ck.user._id, durata: 1});
                    setEditMode(false);
                    getElencoServizi();
                }
            }
        }
    })

    useEffect(() => {
        if(props.servizio) {
            setInfermiereServizioSelezionato(props.servizio);
            getServizio(props.servizio);
            setGiorniSelezionati(GiornoCalendarioUtils.creaElencoGiorni(props.servizio.giorniNo))
            const inizio = props.servizio.fasciaOrariaNo?.orarioInizio ? new Date(props.servizio.fasciaOrariaNo?.orarioInizio) : undefined;
            const fine = props.servizio.fasciaOrariaNo?.orarioFine ? new Date(props.servizio.fasciaOrariaNo?.orarioFine) : undefined;
            setOrarioInizio(inizio ? `${LavoraDate.prendeOrario(inizio)}` : null);
            setOrarioFine(fine ? `${LavoraDate.prendeOrario(fine)}` : null);
            setEditMode(true);
        }
    }, [props.servizio])

    return (
        <div>
        <BootstrapDialog
            className="dialog-servizio-infermiere"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open!}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Configura il servizio
            </BootstrapDialogTitle>
            <DialogContent dividers>

                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Servizio</FormHelperText>
                        <Autocomplete
                            id="combo-box-demo"
                            hidden={editMode}
                            options={ elencoServizi }
                            value={ servizioSelezionato || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                            getOptionLabel={ (option) => option.nome || ""}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => handleElencoServizi(value)} />
                        <OutlinedInput
                            disabled
                            hidden={!editMode}
                            value={infermiereServizioSelezionato?.nomeServizio || ''}
                            />
                    </FormControl>
                </div>

                <div className="riga-due-colonne">
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Prezzo</FormHelperText>
                            <OutlinedInput
                                disabled={editMode && !infermiereServizioSelezionato?.attivo}
                                type="number"
                                value={infermiereServizioSelezionato?.prezzo || ""}
                                onChange={handleChange('prezzo')} 
                                inputProps={{
                                    step: "0.01",
                                    min: servizioSelezionato?.prezzoMin || 0,
                                    max: servizioSelezionato?.prezzoMax || 9999
                                }}
                                onBlur={handleBlur}
                                
                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                />
                        </FormControl>
                        <FormControl className='form-field field-durata' variant="outlined">
                            <FormHelperText>Durata (minuti)</FormHelperText>
                            <OutlinedInput
                                disabled={editMode && !infermiereServizioSelezionato?.attivo}
                                type="number"
                                inputProps={{
                                    min: "15",
                                    max: "180",
                                    step: "15",
                                }}

                                endAdornment={<InputAdornment position="end">
                                <IconButton onClick={increment}>
                                  <AddIcon />
                                </IconButton>
                                <IconButton onClick={decrement}>
                                  <RemoveIcon />
                                </IconButton>
                              </InputAdornment>}
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                                value={infermiereServizioSelezionato?.durata ? infermiereServizioSelezionato.durata * 15 : '15'}
                                
                                onChange={handleChange('durata')} />
                        </FormControl>
                </div>
                <div className="riga-messaggio">
                    <p>{`Min: ${servizioSelezionato?.prezzoMin || "non definito"} - Max: ${servizioSelezionato?.prezzoMax || "non definito"}`}</p>
                </div>
                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Giorni No</FormHelperText>
                        <Autocomplete
                            disabled={editMode && !infermiereServizioSelezionato?.attivo}
                            id="tags-standard"
                            multiple
                            options={giorni}
                            value={giorniSelezionati || [giorni[0]]}
                            getOptionLabel={(option) => option.nome}
                            isOptionEqualToValue={(option, value) => option.val === value.val}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Seleziona giorni..."
                            />
                            )}
                            onChange={(event, value) => handleElencoGiorni(value)}
                            />
                    </FormControl>
                </div>
                    
                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Orario No</FormHelperText>
                        <div className="colonna-orari">
                            <Autocomplete
                                disabled={editMode && !infermiereServizioSelezionato?.attivo}
                                id="box-1"
                                options={ elencoOrari }
                                value={ orarioInizio || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                                renderInput={(params) => <TextField {...params} label="Inizio"/>}
                                onChange={(event, value) => handleOrario("fasciaOrariaNo.orarioInizio", value)} />
                            <Autocomplete
                                disabled={editMode && !infermiereServizioSelezionato?.attivo}
                                id="box-2"
                                options={ elencoOrari }
                                value={ orarioFine || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                                renderInput={(params) => <TextField {...params} label="Fine"/>}
                                onChange={(event, value) => handleOrario("fasciaOrariaNo.orarioFine", value)} />
                        </div>
                    </FormControl>
                </div>

                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Note</FormHelperText>
                        <OutlinedInput
                            disabled={editMode && !infermiereServizioSelezionato?.attivo}
                            value={infermiereServizioSelezionato?.noteAppuntamento || ""}
                            multiline
                            rows={3}
                            onChange={handleChange('noteAppuntamento')} 
                            />
                    </FormControl>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSalvaButton} disabled={editMode && !infermiereServizioSelezionato?.attivo}>Salva</Button>
            </DialogActions>
        </BootstrapDialog>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />

        <LoadingView
                visible={loading}
        />

        </div>
    );
}
export default DialogcalendarioInfermiere;