import { Autocomplete, TextField } from '@mui/material';
import * as React from 'react';


export type Props = {
    provincia?: string;
    onChangeProvincia(value: string | null): void;

};
  
function DropdownProvincie(props: Props) {

    const provincie = [
        "AG",	
        "AL",	
        "AN",	
        "AO",	
        "AQ",	
        "AR",	
        "AP",	
        "AT",	
        "AV",	
        "BA",	
        "BT",	
        "BL",	
        "BN",	
        "BG",	
        "BI",	
        "BO",	
        "BZ",	
        "BS",	
        "BR",	
        "CA",	
        "CL",	
        "CB",	
        "CI",	
        "CE",	
        "CT",	
        "CZ",	
        "CH",	
        "CO",	
        "CS",	
        "CR",	
        "KR",
        "CN",	
        "EN",	
        "FM",	
        "FE",	
        "FI",	
        "FG",	
        "FC",	
        "FR",
        "GE",
        "GO",
        "GR",	
        "IM",
        "IS",	
        "SP",	
        "LT",	
        "LE",	
        "LC",
        "LI",	
        "LO",	
        "LU",	
        "MC",	
        "MN",
        "MS",	
        "MT",	
        "VS",	
        "ME",	
        "MI",	
        "MO",	
        "MB",	
        "NA",	
        "NO",	
        "NU",	
        "OG",	
        "OT",	
        "OR",	
        "PD",	
        "PA",	
        "PR",	
        "PV",	
        "PG",	
        "PU",	
        "PE",	
        "PC",	
        "PI",	
        "PT",	
        "PN",	
        "PZ",	
        "PO",	
        "RG",
        "RA",	
        "RC",	
        "RE",	
        "RI",
        "RN",
        "RM",	
        "RO",	
        "SA",
        "SS",	
        "SV",	
        "SI",
        "SR",	
        "SO",	
        "TA",	
        "TE",	
        "TR",	
        "TO",	
        "TP",	
        "TN",	
        "TV",	
        "TS",	
        "UD",	
        "VA",	
        "VE",
        "VB",	
        "VC",	
        "VR",	
        "VV",	
        "VI",	
        "VT"
    ];

    return (
        <Autocomplete
            disablePortal
            id="combo-box-provincie"
            value={ props.provincia || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
            options={provincie}
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, value) => props.onChangeProvincia(value)}
        />
    );


}

export default DropdownProvincie;