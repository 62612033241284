import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import { InfermiereServizio } from "../models/InfermiereServizio";
import Auth from "./Auth";

export default class InfermieriServizi {

    static async getElencoServiziInfermiere(idInfermiere: string, page: number, limit: number) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        if (page > 0) {
            try {
                const response = await Axios.get(`${Constants.apiWebService.baseUrl}/infermieriServizi?filter=${idInfermiere}&fields=idInfermiere&page=${page}&limit=` + limit, config);
                return response.data;
            }
            catch (err : any) {
                Errors.erroreApi(err.message);  
            }
        }
    } 

    static async addServizioInfermiere(infermiereServizio: InfermiereServizio) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await Axios.post(Constants.apiWebService.baseUrl + '/infermieriServizi',
                infermiereServizio,
                config
            );
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

    static async updateServizioInfermiere(infermiereServizio: InfermiereServizio) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await Axios.patch(Constants.apiWebService.baseUrl + '/infermieriServizi/' + infermiereServizio._id,
                infermiereServizio,
                config
            );
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

    static async deleteServizioInfermiere(infermiereServizio: InfermiereServizio) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await Axios.delete(Constants.apiWebService.baseUrl + '/infermieriServizi/' + infermiereServizio._id, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

    //Servizio del singolo infermiere
    //Restituisce info del servizio dell'infermiere, come ad esempio giorniNo e fasciaOrariaNo
    //Restituisce model "InferiereServizio"
    static async getInfoServizioInfermiereAdmin (idInfermiere: string, idServizio: string) {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/infermieriServizi/single/' + idInfermiere + '/' + idServizio);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

}