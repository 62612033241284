import { useEffect, useState } from 'react';
import { InfermiereInfo } from '../models/InfermiereInfo';
import ApiInfermieri from '../api/Infermieri';
import Cookies from 'js-cookie';
import '../style/styleComponents/BarraSuperiore.css';


function BarraSuperiore() {
    const [utente, setUtente] = useState<InfermiereInfo>();
    const [email, setEmail] = useState<string>();
    
    useEffect(() => {
        if(!utente) {
          let authCookie = Cookies.get('auth');
          if(authCookie) {
              let ck = JSON.parse(authCookie);
              setEmail(ck.user.email);
              getInfermiere(ck.user._id);
          }
        }
      },[utente]) 


    const getInfermiere = async (idUtente: string) => {
        const userInfo: InfermiereInfo = await ApiInfermieri.getSingoloInfermiere(idUtente);
        setUtente(userInfo);
      }

    return (
        <div className="component-barra-superiore">

            <div className="contenuto">

                <div className="riga1">
                    <div className="nome-utente">{`${utente?.nome} ${utente?.cognome}`}</div>
                    <div>{`(${email})`}</div>
                </div>
                <div className='riga2'>
                    <div className={utente?.attivo ? 'active-user' : 'disabled-user'}>{utente?.attivo ? "Utente autorizzato" : "Utente non autorizzato" }</div>
                    <div className="id-utente">{`(ID: ${utente?.idUtente})`}</div>
                </div>

            </div>

        </div>
    );
}

export default BarraSuperiore;