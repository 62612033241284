import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import dayjs, { Dayjs } from "dayjs";
import LavoraDate from "../utils/LavoraDate";
import { GiornoCalendario } from "../models/GiornoCalendario";
import { InfermiereInfo } from "../models/InfermiereInfo";
import Auth from "./Auth";

export default class Infermieri {

  static async getElencoInfermieri() {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
    };

    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/infermieriInfo/?filter=true,false&fields=attivo,isAdmin', config);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async getSingoloInfermiere (idInfermiere: string) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
    };

    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/infermieriInfo/infermiere/' + idInfermiere, config);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async updateInfermiereInfo (infermiereInfo: InfermiereInfo) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    };

    try {
      const response = await Axios.patch(Constants.apiWebService.baseUrl + '/infermieriInfo/' + infermiereInfo._id, infermiereInfo, config);
      return response.data;
    }
    catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }


  static async getInfermiereByFilter (idServizioSelezionato: string, indirizzoSelezionato: string, data: Dayjs | undefined, prezzoMin: string, prezzoMax: string, idStruttura: string | undefined) {
    var paramData = "";
    var orario = 0;
    if (data) {
      const anno = dayjs(data).year();
      const mese = dayjs(data).month() + 1;
      const giorno = dayjs(data).date();
      const dataInserita = anno + '-' + mese + '-' + giorno;
      paramData = '?data='+ dataInserita;
      orario = dayjs(data).hour();
    } else { //se data non è selezionata, metto data di oggi
      const date = new Date(Date.now());
      const anno = date.getFullYear();
      const mese = date.getMonth() + 1;
      const giorno = date.getDate();
      const dataOggi = anno + '-' + mese + '-' + giorno;
      paramData = '?data='+ dataOggi;
      orario = dayjs(date).hour();
    }

    var paramSlot = "&slot=" + orario + ":00";

    
    var paramPrezzoMin = "";
    if (prezzoMin) {
      paramPrezzoMin = '&prezzoMin='+ prezzoMin;
    }
    var paramPrezzoMax = "";
    if (prezzoMax) {
      paramPrezzoMax = '&prezzoMax='+ prezzoMax;
    }
    
    var paramIdStruttura = "";
    if (idStruttura) {
      paramIdStruttura = '&idStruttura=' + idStruttura;
    }

    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/search/' + idServizioSelezionato + '/' + indirizzoSelezionato + paramData + paramSlot + paramPrezzoMin + paramPrezzoMax + paramIdStruttura);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  
  //Restituisce Singolo giorno calendario del singolo infermiere
  static async getGiornoCalendarioInfermiere (idInfermiere: string, _giorno: Date) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };


    const giornoLavorato = LavoraDate.lavoraData(_giorno);
    
    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/giorniCalendario/giornoInfermiere/' + idInfermiere + '/' + giornoLavorato, config);
      return response.data.docs;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async getCalendarioMensile (idInfermiere: string, mese: number, anno: number) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/giorniCalendario/calendarioMensile/' + idInfermiere + '/' + mese + '/' + anno, config);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async liberaSlot (idInfermiere?: string, dateStart?: string, dateEnd?: string) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    try {
      if(!idInfermiere || !dateStart || !dateEnd || dayjs(dateStart).tz("Europe/Rome") < dayjs().tz("Europe/Rome").add(1, 'day'))
        throw new Error("Impossibile eseguire la chiamata liberaSlot");

      const response = await Axios.put(Constants.apiWebService.baseUrl + '/giorniCalendario/liberaSlot/' + idInfermiere,
      {
        dateStart: dateStart,
        dateEnd: dateEnd
      },  config);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async bloccaSlot (idInfermiere?: string, dateStart?: string, dateEnd?: string) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    try {
      if(!idInfermiere || !dateStart || !dateEnd || dayjs(dateStart).tz("Europe/Rome") < dayjs().tz("Europe/Rome").add(1, 'day'))
        throw new Error("Impossibile eseguire la chiamata bloccaSlot");
        
      const response = await Axios.put(Constants.apiWebService.baseUrl + '/giorniCalendario/bloccaSlot/' + idInfermiere,
      {
        dateStart: dateStart,
        dateEnd: dateEnd
      }, config);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async ripetiGiornoCalendario (idInfermiere?: string, giorno?: GiornoCalendario) {
    const token = Auth.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    
    try {
      if(!giorno)
        throw new Error("Impossibile eseguire la chiamata ripetiGiornoCalendario");

      const response = await Axios.put(Constants.apiWebService.baseUrl + '/giorniCalendario/ripetiGiorno/' + idInfermiere,
      {
        giornoCalendario: giorno
      }, config);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  //Giorni calendario del singolo infermiere
  //Restituisce tutti giorni di calendario dell'infermiere a partire da quella data nel param in poi
  static async getGiorniCalendarioInfermiereAdmin (idInfermiere: string, _giorno: Date) {
    //il param giornolavorato, lo prendo dalla prima disponibilità di quell'infermiere.
    const date = new Date(_giorno);
    const anno = date.getFullYear();
    const mese = date.getMonth() + 1;
    const giorno = date.getDate();
    const giornoLavorato = anno + '-' + mese + '-' + giorno;
    
    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/giorniCalendario/calendarioInfermiere/' + idInfermiere + '/' + giornoLavorato + '?limit=50&sort=data&order=1');
      return response.data.docs;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }


}