import CalendarioView from '../components/calendario/CalendarioView';
import ApiInfermieri from '../api/Infermieri';
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEffect, useState } from 'react';
import { GiornoCalendario } from '../models/GiornoCalendario';
import Cookies from "js-cookie";
import LoadingView from '../components/generics/LoadingView';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfermiereInfo } from '../models/InfermiereInfo';
import UtentiAdmin from '../api/UtentiAdmin';
import Utenti from '../api/Utenti';

function Calendario() {
  const [actionDateStart, setActionDateStart] = useState<Dayjs>();
  const [actionDateEnd, setActionDateEnd] = useState<Dayjs>();
  const [idInfermiere, setIdInfermiere] = useState<string>();
  const [infermiere, setInfermiere] = useState<InfermiereInfo>();
  const [isAdmin, setIsAdmin] = useState(false);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const getCalendarioMensile = async (day: Dayjs) => {
    return idInfermiere ? ApiInfermieri.getCalendarioMensile(idInfermiere, day.month() + 1, day.year()) : undefined;
  }

  const getCalendario = async (day: Dayjs) => {
    return idInfermiere ? ApiInfermieri.getGiornoCalendarioInfermiere(idInfermiere, day.toDate()) : undefined;
  }

  const getInfermiere = async (idInfermiere: string) => {
    const inf = await ApiInfermieri.getSingoloInfermiere(idInfermiere);
    setInfermiere(inf);
  }

  const changeDateStart = (date: Dayjs) => {
    setActionDateStart(date);
    if(!actionDateEnd || date > actionDateEnd) {
      setActionDateEnd(date);
    }

  }

  const changeDateEnd = (date: Dayjs) => {
    if(actionDateStart && date < actionDateStart) {
      setActionDateEnd(dayjs(actionDateStart));
    }
    else {
      setActionDateEnd(date);
    }
  }

  const liberaSlot = () => {
    return ApiInfermieri.liberaSlot(idInfermiere, actionDateStart?.toISOString(), actionDateEnd?.toISOString());
  }

  const bloccaSlot = () => {
    return ApiInfermieri.bloccaSlot(idInfermiere, actionDateStart?.toISOString(), actionDateEnd?.toISOString());
  }

  const liberaSingoloSlot = (actionDateStart: dayjs.Dayjs, actionDateEnd: dayjs.Dayjs) => {
    return ApiInfermieri.liberaSlot(idInfermiere, actionDateStart?.toISOString(), actionDateEnd?.toISOString());
  }

  const bloccaSingoloSlot = (actionDateStart: dayjs.Dayjs, actionDateEnd: dayjs.Dayjs) => {
    return ApiInfermieri.bloccaSlot(idInfermiere, actionDateStart?.toISOString(), actionDateEnd?.toISOString());
  }

  const bloccaGiorno = (startDate: string, endDate: string) => {
    return ApiInfermieri.bloccaSlot(idInfermiere, startDate, endDate);
  }

  const ripetiGiorno = (giorno?: GiornoCalendario) => {
    return ApiInfermieri.ripetiGiornoCalendario(idInfermiere, giorno);
  }

  const checkAndSetInfermiere = async (id: string) => {
    const usr = await Utenti.getMe();
    if(usr.ruolo !== 'admin')
      navigate('/');
    else {
      setIdInfermiere(id);
      getInfermiere(id);
    }
  }

  useEffect(() => {
    if(!actionDateStart) {
      const dateNow = dayjs().tz("Europe/Rome");
      let startDate = dateNow.add(1, 'day').add(1, 'hour');
      startDate = startDate.minute(0).second(0).millisecond(0);
      const endDate = dayjs(startDate);
      setActionDateStart(startDate);
      setActionDateEnd(endDate);
      /*const dateNow = new Date();
      const startDate = new Date(
          `${dateNow.getFullYear()}-${(dateNow.getMonth()+1).toString().padStart(2,'0')}-${dateNow.getDate().toString().padStart(2, '0')} ${(dateNow.getHours()+1).toString().padStart(2,'0')}:00:00`
      );
      const endDate = new Date(startDate);
      setActionDateStart(dayjs(startDate));
      setActionDateEnd(dayjs(endDate));*/
    }
  })

  useEffect(() => {
      if(!idInfermiere) {
        const id = queryParameters.get("id");

        if(id) {
          setIsAdmin(true);
          checkAndSetInfermiere(id);
        }
        else {
          let authCookie = Cookies.get('auth');
          let user = {_id: ""}
          if(authCookie) {
              let ck = JSON.parse(authCookie);
              user = ck.user;
              setIdInfermiere(user._id);
          }
        }
    }
  });

  return (
    <div className="page-template">
        <div className='main-content'>
          <h2>{isAdmin? `Calendario disponibilità di ${infermiere?.nome} ${infermiere?.cognome}` : 'Calendario disponibilità'}</h2>
          <a onClick={() => navigate(-1)} style={{display: isAdmin? 'block' : 'none'}}>Indietro</a>
          <CalendarioView 
            actionDateStart={actionDateStart}
            actionDateEnd={actionDateEnd}
            onChangeDateStart={changeDateStart}
            onChangeDateEnd={changeDateEnd}
            getCalendarioMensile={getCalendarioMensile} 
            getCalendario={getCalendario}
            liberaSlot={liberaSlot}
            bloccaSlot={bloccaSlot}
            liberaSingoloSlot={liberaSingoloSlot}
            bloccaSingoloSlot={bloccaSingoloSlot}
            bloccaGiorno={bloccaGiorno}
            ripetiGiorno={ripetiGiorno} />
        </div>
    </div>
  );
}

export default Calendario;