import React, { useEffect, useState } from "react";
import { InfermiereInfo } from "../../models/InfermiereInfo";
import Constants from "../../utils/Constants";
import '../../style/styleComponents/ImmagineProfilo.css';


interface ComponentProps {
    utente?: InfermiereInfo;
    selectedFile?: File | null;
    handleFileChange(file: File): void;
    
}
export type Props = ComponentProps;


function ImmagineProfilo (props: Props) {
  const [utenteD, setUtenteD] = useState<InfermiereInfo>();
  const [fileCaricato, setFileCaricato] = useState(false);



  useEffect(() => {
    if(!utenteD && props.utente) {
      setUtenteD(props.utente);
    }
  }) 


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFileCaricato(true);
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('image/jpeg')) {
          props.handleFileChange(file);
        } else {
          alert('Si prega di selezionare un file JPG valido.');
        }
    };
    

    const mostraImmagine = () => {
      //if (utenteD && utenteD.img) {
      if (utenteD && utenteD.img || utenteD && props.selectedFile) {
        if (fileCaricato && props.selectedFile) {
          return <img src={URL.createObjectURL(props.selectedFile)}/>;
        }
        return <img src={Constants.azureContainer.url + "imgprofilo/" + utenteD?.img + ".jpg"}/>;
      }
       else {
        return <img src={Constants.azureContainer.url + "imgprofilo/placeholder.jpg"}/>;
       }
    }


    return (
        <div className="">
            <div className="img-profilo">
            <div className="box-img">
              {mostraImmagine()}
            </div>
            <div className="upload-img">
              {/*props.selectedFile && (
                <div>
                  <img
                    src={URL.createObjectURL(props.selectedFile)}
                    style={{ maxWidth: '100%', maxHeight: '300px' }}
                  />
                </div>
              )*/}

              <div>
                <label htmlFor="files" className="btn-standard">
                  Cambia l'immagine
                </label>
                <input
                  id="files"
                  type="file"
                  accept=".jpg, .jpeg"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>

            </div>
          </div>
        </div>
    );
}

export default ImmagineProfilo;