import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './style/index.css';
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import { reactPlugin } from "./AppInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import Calendario from './pages/Calendario';
import Servizi from './pages/Servizi';
import Prenotazioni from './pages/Prenotazioni';
import PrenotazioneInfo from './pages/PrenotazioneInfo';
import UtentiAdmin from './pages/admin/UtentiAdmin';
import UtenteInfoAdmin from './pages/admin/UtenteInfoAdmin';
import ServiziAdmin from './pages/admin/ServiziAdmin';
import Fatture from './pages/Fatture';
import FattureAdmin from './pages/admin/FattureAdmin';
import PrenotazioniAdmin from './pages/admin/PrenotazioniAdmin';
import StruttureAdmin from './pages/admin/StruttureAdmin';
import AddPrenotazioniAdmin from './pages/admin/AddPrenotazioneAdmin';
import EditPrenotazione from './pages/EditPrenotazione';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="calendario" element={<Calendario />} />
            <Route path="servizi" element={<Servizi />} />
            <Route path="prenotazioni" element={<Prenotazioni />} />
            <Route path="fatture" element={<Fatture />} />
            <Route path="prenotazione" element={<PrenotazioneInfo />} />
            <Route path="utenti" element={<UtentiAdmin />} />
            <Route path="utente" element={<UtenteInfoAdmin />} />
            <Route path="servizi-admin" element={<ServiziAdmin />} />
            <Route path="fatture-admin" element={<FattureAdmin />} />
            <Route path="prenotazioni-admin" element={<PrenotazioniAdmin />} />
            <Route path="strutture-admin" element={<StruttureAdmin />} />
            <Route path="nuova-prenotazione" element={<AddPrenotazioniAdmin />} />
            <Route path="modifica-prenotazione" element={<EditPrenotazione />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
    </BrowserRouter>
  </AppInsightsErrorBoundary>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
