import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

export default class LavoraDate {

    //Prende formato data e restituisce stringa "12 Maggio 2023" (da mostrare a frontend)
    static lavoraDataConMese (formatoData: Date) {
        const nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        const date = new Date(formatoData);
        const anno = date.getFullYear();
        const mese = nomiMesi[date.getMonth()];
        const giorno = date.getDate();
        const dataLavorata = giorno + ' ' + mese + ' ' +anno;
        return dataLavorata;
    }


    //Prende formato data e restituisce stringa "2023-12-22" (formato che vogliono alcune api)
    static lavoraData (formatoData: Date) {
        const date = new Date(formatoData);
        const anno = date.getFullYear();
        const mese = (date.getMonth() + 1).toString().padStart(2, "0");
        const giorno = date.getDate().toString().padStart(2, "0");
        const dataLavorata = anno + '-' + mese + '-' + giorno;
        return dataLavorata;
    }

    
    //Prende formato data e restituisce stringa "2023-07-10T17:00:00" (formato che vogliono alcune api)
    static lavoraDataPerApi (data: Date) {
        const dataFormattata = data.getFullYear() + '-' + String(data.getMonth()+1).padStart(2, "0") + '-' + data.getDate().toString().padStart(2, "0") + 'T' 
            + String(data.getHours()).padStart(2, "0") + ':' + String(data.getMinutes()).padStart(2, "0") + ':' + String(data.getSeconds()).padStart(2, "0");
        return dataFormattata;
    }


    //Prende formato data e restituice stringa oraria "9:30"
    static prendeOrario (formatoData: Date) {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const date = dayjs(formatoData).tz("Europe/Rome");
        const ora = date.hour();
        const minuti = String(date.minute()).padStart(2, "0");
        return ora + ':' + minuti;

        /*const date = new Date(formatoData);
        const ora = date.getHours();
        const minuti = String(date.getMinutes()).padStart(2, "0");
        return ora + ':' + minuti;*/
    }
}