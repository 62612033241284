import '../style/stylePages/Home.css';
import '../style/Algolia.css';
import { Autocomplete as MuiAutocomplete, Button } from '@mui/material';

import { useEffect, useState } from 'react';
import ApiInfermieri from '../api/Infermieri';
import UploadFile from '../api/UploadFile';
import LoadingView from '../components/generics/LoadingView';
import { InfermiereInfo } from '../models/InfermiereInfo';
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import DatiUtente from '../components/home/DatiUtente';
import DatiFatturazione from '../components/home/DatiFatturazione';
import CambioPassword from '../components/home/CambioPassword';
import Auth from '../api/Auth';
import ImmagineProfilo from '../components/home/ImmagineProfilo';
import { useLocation, useNavigate } from 'react-router-dom';
import Utenti from '../api/Utenti';


function Home() {
  const [loading, setLoading] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [errorDistance, setErrorDistance] = useState(false);
  const [errorRagioneSociale, setErrorRagioneSociale] = useState(false);
  const [errorVia, setErrorVia] = useState(false);
  const [errorCap, setErrorCap] = useState(false);
  const [errorCitta, setErrorCitta] = useState(false);
  const [errorProvincia, setErrorProvincia] = useState(false);
  const [errorCodiceFiscale, setErrorCodiceFiscale] = useState(false);
  const [errorPartitaIva, setErrorPartitaIva] = useState(false);
  const [errorSdi, setErrorSdi] = useState(false);
  const [utente, setUtente] = useState<InfermiereInfo>();
  const [email, setEmail] = useState<string>();
  const [indirizzoUtente, setIndirizzoUtente] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const getInfermiere = async (idUtente: string) => {
    const userInfo: InfermiereInfo = await ApiInfermieri.getSingoloInfermiere(idUtente);
    setUtente(userInfo);
  }

  const handleChangePassword = async (vecchiaPassword: string, nuovaPassword: string) => {
    setLoading(true);
    
    try {
      await Auth.ChangePassword(vecchiaPassword, nuovaPassword);
      toast.success('Password modificata con successo!');
    }
    catch(err) {
      toast.error("Impossibile modificare la password")
    }

    setLoading(false);
  }


  const handleFileChange = (selectedFile: File) => {
    setSelectedFile(selectedFile);
  };


  const handleSave = async () => {
    setLoading(true);
    //Se c'è una img caricata, faccio l'upload:
    if (selectedFile && utente) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      try{
        const response = await UploadFile.inviaImg(formData, utente);
      }
      catch (err) {
        toast.error("Errore durante l'upload dell'immagine");
        setLoading(false); 
        return;
      }
    }

    if(utente) {
      const user = cloneDeep(utente);
      if(user) {
        if(indirizzoUtente) {
          user.indirizzo = indirizzoUtente;
          setUtente(user);
        }
        
        if(!user.indirizzo) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorAddress(true);
          setLoading(false);
          return;
        }

        if(!user.distanza) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorDistance(true);
          setLoading(false);
          return;
        }

        //
        if(!user.datiFatturazione?.nome) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorRagioneSociale(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.indirizzo) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorVia(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.cap) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorCap(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.citta) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorCitta(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.provincia) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorProvincia(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.codiceFiscale) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorCodiceFiscale(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.partitaIva) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorPartitaIva(true);
          setLoading(false);
          return;
        }
        if(!user.datiFatturazione.sdi) {
          toast.error("Riempire tutti i campi obbligatori")
          setErrorSdi(true);
          setLoading(false);
          return;
        }
        //

        try {
          setErrorAddress(false);
          setErrorDistance(false);
          user.img = undefined;
          await ApiInfermieri.updateInfermiereInfo(user!);
          toast.success('Profilo aggiornato con successo!');
        }
        catch(err) {
          toast.error("Errore durante l'aggiornamento del profilo")
        }
      }
      setUtente(undefined);
    }
    setLoading(false);
  }

  const checkAndSetInfermiere = async (id: string) => {
    const usr = await Utenti.getMe();
    if(usr.ruolo !== 'admin')
        navigate('/');
    else {
        setEmail(usr.email);
        getInfermiere(id);
    }
  }
    
  useEffect(() => {
    if(!utente) {
      setLoading(true);
      const id = queryParameters.get("id");
      if(id) {
          setIsAdmin(true);
          checkAndSetInfermiere(id);
      }
      else {
        let authCookie = Cookies.get('auth');
        if(authCookie) {
            let ck = JSON.parse(authCookie);
            setEmail(ck.user.email);
            getInfermiere(ck.user._id);
        }
      }
    }
  },[utente]) 

  return (
    <div className="page-template">
        <div className='main-content' style={{ visibility: utente ? 'visible': 'hidden'}}>
          <h2 style={{display: isAdmin? 'block' : 'none'}}>{`Modifica profilo di ${utente?.nome} ${utente?.cognome}`}</h2>
          <a onClick={() => navigate(-1)} style={{display: isAdmin? 'block' : 'none'}}>Indietro</a>

          <ImmagineProfilo 
            utente={utente}
            selectedFile={selectedFile}
            handleFileChange={handleFileChange}
          />
   
          <div className='user-info'>     
            <DatiUtente 
              errorAddress={errorAddress}
              errorDistance={errorDistance}
              utente={utente}
              onChange={setUtente}
              onChangeIndirizzo={setIndirizzoUtente}
              isLoading={setLoading}
            />
          </div>
          
          <div className='user-info'>
            <h3>Dati per la fatturazione</h3>
            <DatiFatturazione
              errorRagioneSociale={errorRagioneSociale}
              errorVia={errorVia}
              errorCap={errorCap}
              errorCitta={errorCitta}
              errorProvincia={errorProvincia}
              errorCodiceFiscale={errorCodiceFiscale}
              errorPartitaIva={errorPartitaIva}
              errorSdi={errorSdi}
              utente={utente}
              onChange={setUtente}
            />
          </div>

          <div className='change-pw'>
            <h3>Password</h3>
            <CambioPassword
              onChange={handleChangePassword} />
          </div>

          <div className='button-row'>
            <Button variant="contained" onClick={handleSave}>Salva</Button>
          </div>


        </div>
        <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
        />
        <LoadingView
          visible={loading}
        />
    </div>
  );
}

export default Home;