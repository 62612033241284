import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";
import { InfermiereInfo } from "../models/InfermiereInfo";


export default class UploadFile {

  static async inviaImg(selectedFileFormData: FormData, infermiereInfo: InfermiereInfo) {

    if (selectedFileFormData && infermiereInfo) {

      try {
        const token = Auth.getToken();
        const config = {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
        };

        const response = await Axios.post(Constants.apiWebService.baseUrl + '/uploadfile/' + infermiereInfo._id, selectedFileFormData, config);
        return response.data;
      }
      catch (err : any) {
        Errors.erroreApi(err.message);  
      }

    }

  }

}