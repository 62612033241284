import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Prenotazione } from '../../models/Prenotazione';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import '../../style/styleComponents/PrenotazioneInfoForm.css';
import { InfermiereInfo } from '../../models/InfermiereInfo';
import LoadingView from '../../components/generics/LoadingView';
import Cookies from 'js-cookie';


interface ComponentProps {
    prenotazione?: Prenotazione;
}
export type Props = ComponentProps;

function PrenotazioneInfoForm(props: Props) {
    const [ck, setCk] = useState<any>();
    dayjs.extend(utc);
    dayjs.extend(timezone);
    
    const dataPrenotazione = dayjs(props.prenotazione?.data).tz("Europe/Rome")
    const dataString = dataPrenotazione.locale('it').format('DD/MM/YYYY')
    const orarioString = dataPrenotazione.locale('it').format('HH:mm')

    const dataCreazione = dayjs(props.prenotazione?.createdAt!).tz("Europe/Rome")
    const dataCreazioneString = dataCreazione.locale('it').format('DD/MM/YYYY')
    
    const nomePaziente = props.prenotazione?.paziente?.nome ? `${props.prenotazione?.paziente?.nome} ${props.prenotazione?.paziente?.cognome}` :
        `${props.prenotazione?.utentePrenotante?.nome} ${props.prenotazione?.utentePrenotante?.cognome}`;
    const emailPaziente = props.prenotazione?.paziente?.email ? props.prenotazione?.paziente?.email : "Non comunicata";
    const telPaziente = props.prenotazione?.paziente?.telefono ? props.prenotazione?.paziente?.telefono : "Non comunicato";
    const cfPaziente = props.prenotazione?.paziente?.codiceFiscale ? props.prenotazione?.paziente?.codiceFiscale : props.prenotazione?.utentePrenotante?.codiceFiscale;


    useEffect(() => {
        if(!ck) {
          let authCookie = Cookies.get('auth');
          if(authCookie) {
            let cookie = JSON.parse(authCookie);
            if(cookie) {
              setCk(cookie);
              return;
            }
          }
        }
      },[ck])
 


    return(
        <div className='form-prenotazioni' style={{ visibility: props.prenotazione ? 'visible': 'hidden'}}>
            <div className='flex-container'>
                <div>
                    <div className='flex-container-row'><b>Stato </b><p className={props.prenotazione?.stato ? "confermata" : "cancellata"}>{`${props.prenotazione?.stato ? "Confermata" : "Cancellata"}`}</p></div>
                    <div className='flex-container-row'><b>Id Prenotazione </b><p>{props.prenotazione?._id}</p></div>
                    <div className='flex-container-row'><b>Data del servizio </b><p>{dataString}</p></div>
                    <div className='flex-container-row'><b>Indirizzo </b><p>{props.prenotazione?.indirizzo}</p></div>
                    <div className='flex-container-row'><b>Prezzo </b><p>{props.prenotazione?.prezzoTotale?.toFixed(2)} €</p></div>
                    <div className='flex-container-row'><b>Commissione per Futura </b><p>{`${props.prenotazione?.prezzoCommissioneFutura?.toFixed(2)} €`}</p></div>
                </div>

                <div>
                    <div className='flex-container-row-empty'></div>
                    <div className='flex-container-row'><b>Servizio </b><p>{props.prenotazione?.nomeServizio}</p></div>
                    <div className='flex-container-row'><b>Orario </b><p>{orarioString}</p></div>
                    <div className='flex-container-row'><b>Pagamento </b><p>in loco</p></div>
                    <div className='flex-container-row'><b>Netto </b><p>{`${props.prenotazione?.prezzoNetto?.toFixed(2)} €`}</p></div>
                    <div className='flex-container-row'><b>Data creazione </b><p>{dataCreazioneString}</p></div>

                </div>
            </div>

            <div className='flex-container'>{ck && ck.user && ck.user.ruolo && ck.user.ruolo === 'admin' ? 
                <div>
                    <h3>Info Infermiere</h3>
                    <div className='flex-container-row'> 
                        <b>Id infermiere: </b>
                        <p>{props.prenotazione?.idInfermierePrenotato}</p>
                    </div>
                    <div className='flex-container-row'> 
                        <b>Nome infermiere: </b>
                        <p>{props.prenotazione?.nomeInfermierePrenotato}</p>
                    </div>
                </div>
            : ""}</div>

            <div className='flex-container'>
                <div>
                    <h3>Dati di chi ha prenotato</h3>
                    <div className='flex-container-row' style={{ display: props.prenotazione?.utentePrenotante?.idUtente ? 'block': 'none'}}><b>Id Utente: </b><p>{props.prenotazione?.utentePrenotante?.idUtente}</p></div>
                    <div className='flex-container-row'><b>Nome: </b><p>{`${props.prenotazione?.utentePrenotante?.nome} ${props.prenotazione?.utentePrenotante?.cognome}`}</p></div>
                    <div className='flex-container-row'><b>E-mail: </b><p>{props.prenotazione?.utentePrenotante?.email}</p></div>
                    <div className='flex-container-row'><b>Telefono: </b><p>{props.prenotazione?.utentePrenotante?.telefono}</p></div>
                </div>

                <div>
                    <h3>Dati paziente</h3>
                    {props.prenotazione?.paziente ? (
                        <div>
                            <div className='flex-container-row'><b>Nome: </b><p>{nomePaziente}</p></div>
                            <div className='flex-container-row'><b>E-mail: </b><p>{emailPaziente}</p></div>
                            <div className='flex-container-row'><b>Telefono: </b><p>{telPaziente}</p></div>
                        </div>
                        ) : "I dati coincidono con quelli di chi ha prenotato."
                    }
                </div>
            </div>

            <div className='flex-container'>
                <div>
                    <h3>Note del paziente sulla prenotazione</h3>
                    <p>{props.prenotazione && props.prenotazione.note ? props.prenotazione.note : "Nessuna nota del paziente"}</p>
                </div>
                <div>
                    <h3>Note dell'infermiere sul servizio</h3>
                    {props.prenotazione && props.prenotazione.noteAppuntamento ? props.prenotazione.noteAppuntamento : "Nessuna nota dell'infermiere."}
                </div>
            </div>

        </div>
    );
}

export default PrenotazioneInfoForm;