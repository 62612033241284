import { useEffect, useState } from "react";
import { Utente } from "../../models/Utente";
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ApiStrutture from '../../api/Strutture';
import Cookies from "js-cookie";
import { cloneDeep, set } from "lodash";
import GiornoCalendarioUtils from "../../utils/GiornoCalendarioUtils";
import ButtonMenu from "../../components/generics/ButtonMenu";
import '../../style/stylePages/admin/UtentiAdmin.css';
import { ToastContainer, toast } from "react-toastify";
import LavoraDate from "../../utils/LavoraDate";
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Struttura } from "../../models/Struttura";
import DialogStrutturaAdmin from "../../components/admin/DialogStrutturaAdmin";



function StruttureAdmin() {
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta
    const [struttura, setStruttura] = useState<any>();
    const [strutture, setStrutture] = useState<Struttura[]>();
    const [strutturaSelezionato, setStrutturaSelezionato] = useState<Struttura>();
    const [filtri, setFiltri] = useState({nome: undefined});
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);

    
    const getStrutture = async (page: number, limit: number = 10) => {
        const data = await ApiStrutture.getElencoStruttureAdmin(filtri, page, limit);
        if(data) {
            setStrutture(data.docs);
            setTotalPages(data.totalPages);

        }
    }

    const deleteStruttura = async (struttura: Struttura) => {
        try {
            //const data = await ApiInfermieriStrutture.deleteStrutturaInfermiere(struttura);
            setStrutturaSelezionato(undefined);
            setStrutture(undefined);
            toast.success("Struttura cancellato con successo");
        } catch (err: any) {
            toast.error(err);
        }
        
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = cloneDeep(filtri);
        set(f, field, event.target.value);
        setFiltri(f);
        setStrutture(undefined);
    };

    const openDialogFunc = (struttura?: Struttura) => {
        if(struttura)
            setStrutturaSelezionato(struttura);
        
        setOpenDialog(true);
    }

    const closeDialogAndRefresh = () => {
        setStrutture(undefined);
        setStrutturaSelezionato(undefined);
        setOpenDialog(false);
    }

    const closeDialog = () => {
        setStrutturaSelezionato(undefined);
        setOpenDialog(false);
    }

    //Paginazione
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
        getStrutture(newPage);
    }

    useEffect(() => {
        if(!strutture) {
            getStrutture(page);
        }
    });
    
    return (
        <div className="page-template">
            <div className='main-content'>
                <h2>Strutture</h2>
                <div className="top-buttons">
                    <Button variant="contained" onClick={() => openDialogFunc()}>Aggiungi struttura</Button>
                    {/*<Button variant="contained" onClick={() => algoliaReindex()}>Algolia Reindex</Button>*/}
                </div>
                <div className="filtri" style={{marginTop: 50}}>
                    <h3 style={{marginTop: 13}}>Filtri: </h3>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.nome || ''}
                            onChange={handleChange('nome')}
                            label="Nome Struttura" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className="tabella-servizi" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Stato</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {strutture?.map((row: Struttura) => (
                            <TableRow
                                hover
                                key={row._id}
                                className="table-row"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell onClick={() => openDialogFunc(row)}>{row._id}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.nome}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)} className={row.attivo ? "green-label" : "red-label"}>
                                    {row.attivo ? "Attivo" : "Non attivo"}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack spacing={2} className='paginazione'>
                    <Pagination count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
                </Stack>
            </div>
            <DialogStrutturaAdmin open={openDialog} onClose={closeDialog} onSave={closeDialogAndRefresh} struttura={strutturaSelezionato} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>

    );
}

export default StruttureAdmin;