import { Outlet } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style/Colori-Typography.css';
import '../style/Menu.css';
import BarraSuperiore from "../components/BarraSuperiore";
import Footer from "../components/Footer";
import Menu from "../components/Menu";

const Layout = () => {
  return (
    <>
      <Menu/>
      <BarraSuperiore/>
      <Outlet />
      <Footer/>
    </>
  )
};

export default Layout;