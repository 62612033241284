import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";

export default class Fatture { 

    static async getElencoFatture(idInfermiere: string, anno?: number, page?: number, limit?: number) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        try {
            if(!anno) {
                anno = new Date().getFullYear();
            }
            if(!page) {
                page = 1;
            }

            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/fatture?filter=${idInfermiere},${anno}&fields=infermiere.idInfermiere,annoPeriodoRiferimento&page=${page}&limit=` + limit, config);
            return response.data;
            
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    static async getElencoFattureAdmin(anno?: number, filtri?: any, page: number = 1, limit: number = 10) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        try {
            if(!anno) {
                anno = new Date().getFullYear();
            }
            if(!page) {
                page = 1;
            }
            let filters = anno.toString();
            let fields = "annoPeriodoRiferimento";

            if(filtri) {
                if(filtri.periodo) {
                    filters += `,${filtri.periodo}`;
                    fields += `,mesePeriodoRiferimento`;
                }
                if(filtri.numero) {
                    filters += `,${filtri.numero}`;
                    fields += `,numero`;
                }
                if(filtri.infermiere) {
                    filters += `,${filtri.infermiere}`;
                    fields += `,infermiere.nomeInfermiere`;
                }
                if(filtri.struttura) {
                    filters += `,${filtri.struttura}`;
                    fields += `,infermiere.nomeStruttura`;
                }
            }

            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/fatture?filter=${filters}&fields=${fields}&page=${page}&limit=${limit}`, config);
            return response.data;
            
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

}