
import '../style/styleComponents/Footer.css';


function Footer() {
    
    return (
        <div className="">

        </div>
    );
}

export default Footer;