import { SyntheticEvent, useEffect, useState } from "react";
import ApiPrenotazioni from '../../api/Prenotazioni';
import Cookies from "js-cookie";
import { cloneDeep, set } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { Prenotazione } from "../../models/Prenotazione";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete as MuiAutocomplete, Button, FormControl, FormHelperText, OutlinedInput, TextField, FormGroup, FormControlLabel, Checkbox, InputAdornment } from "@mui/material";
import { InfermiereInfo } from "../../models/InfermiereInfo";
import ApiInfermieri from "../../api/Infermieri";
import ApiInfermieriServizi from "../../api/InfermieriServizi";
import ApiServizi from "../../api/Servizi";
import { InfermiereServizio } from "../../models/InfermiereServizio";
import DialogCalendarioAdmin from "../../components/admin/DialogCalendarioAdmin";
import '../../style/stylePages/admin/AddPrenotazioneAdmin.css'
import Autocomplete from "react-google-autocomplete";
import Constants from "../../utils/Constants";
import { AddressGoogle } from "../../models/AddressGoogle";
import LoadingView from "../../components/generics/LoadingView";
import { Servizio } from "../../models/Servizio";


function AddPrenotazioniAdmin() {
    const [loading, setLoading] = useState<boolean>(true);
    const [elencoInfermieri, setElencoInfermieri] = useState<InfermiereInfo[]>([]);
    const [infermieriFetched, setInfermieriFetched] = useState<boolean>(false);
    const [infermiereSelezionato, setInfermiereSelezionato] = useState<InfermiereInfo>();
    const [elencoServiziInfermiere, setElencoServiziInfermiere] = useState<InfermiereServizio[]>([]);
    const [serviziInfermiereFetched, setServiziInfermiereFetched] = useState<boolean>(false);
    const [servizioInfermiereSelezionato, setServizioInfermiereSelezionato] = useState<InfermiereServizio>();
    const [openDialogCalendar, setOpenDialogCalendar] = useState(false);    //Popup con calendario
    const [dataOraPrenotazione, setDataOraPrenotazione] = useState<string>();
    const [dataOraFormattata, setDataOraFormattata] = useState<string>();
    const [indirizzo, setIndirizzo] = useState<string>();
    const [prenotazione, setPrenotazione] = useState<Prenotazione>({});
    const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
    const [servizioSelezionato, setServizioSelezionato] = useState<Servizio>();


    const navigate = useNavigate();

    const getElencoInfermieri = async () => {
        const infermieri = await ApiInfermieri.getElencoInfermieri();
        setElencoInfermieri(infermieri.docs);
        setInfermieriFetched(true);

        setLoading(false);
    }

    const getElencoServiziInfermiere = async () => {
        const servizi = await ApiInfermieriServizi.getElencoServiziInfermiere(infermiereSelezionato!.idUtente!, 1, 999);
        setElencoServiziInfermiere(servizi.docs);
        setServiziInfermiereFetched(true);
    }

    const handleCambioInfermiere = (value: InfermiereInfo | null) => {
        if(value) {
            setInfermiereSelezionato(value);

            setElencoServiziInfermiere([]);
            setServizioInfermiereSelezionato(undefined);
            setServiziInfermiereFetched(false);

            setDataOraPrenotazione(undefined);
            setDataOraFormattata(undefined);
        }
        
    }

    const handleCambioServizioInfermiere = async (value: InfermiereServizio | null) => {
        if(value) {
            setServizioInfermiereSelezionato(value);
            setDataOraPrenotazione(undefined);
            setDataOraFormattata(undefined);
            const servizio: Servizio = await ApiServizi.getSingoloServizio(value.idServizio!);
            setServizioSelezionato(servizio);
        }
        
    }

    const closeDialogCalendar = () => {
        setOpenDialogCalendar(false);
    }

    const selezionaDataOra = (data: string, dataFormattata:string) => {
        setDataOraPrenotazione(data);
        setDataOraFormattata(dataFormattata);
        setOpenDialogCalendar(false);
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let pren = cloneDeep(prenotazione);
        let value: any = event.target.value;
        if(field === "prezzoTotale") 
            value = parseFloat(value);
        set(pren, field, value);
        setPrenotazione(pren);
    };


    /*const handleChangeIndirizzoText = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(!event.target.value || event.target.value === '') {
        const pren = cloneDeep(prenotazione);
        set(pren, "indirizzo", undefined);
        setPrenotazione(pren);
      }
    }*/

    const handleNonInviareEmail = (event: SyntheticEvent<Element, Event>) => {
        var value = (event.target as HTMLButtonElement).value;
        if(value == "true") {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.nonInviareEmail = false;
            setPrenotazione(newPrenotazione);
        } else {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.nonInviareEmail = true;
            setPrenotazione(newPrenotazione);
        }
    }

    const handleBlur = () => {
        const pren = cloneDeep(prenotazione);

        const min = servizioSelezionato?.prezzoMin || 0;
        const max = servizioSelezionato?.prezzoMax || 9999;
        let correctedValueString = (prenotazione?.prezzoTotale || 0).toFixed(2);
        let correctedValue = parseFloat(correctedValueString);
        let prezzo = parseFloat(correctedValueString);

        if ( prezzo < min) {
            correctedValue = min;
        } else if (prezzo > max) {
            correctedValue = max;
        }

      
        if (correctedValue !== prenotazione?.prezzoTotale) {
            // Aggiorna lo stato con il valore corretto
            if(pren) {
                set(pren, "prezzoTotale", correctedValue);
                setPrenotazione(pren);
            }
        }
      };


    const onSave = async () => {
        setLoading(true);
        var newPrenotazione = cloneDeep(prenotazione);
        newPrenotazione.idServizio = servizioInfermiereSelezionato!.idServizio;
        newPrenotazione.nomeServizio = servizioInfermiereSelezionato!.nomeServizio;
        newPrenotazione.idInfermierePrenotato = infermiereSelezionato!.idUtente;
        newPrenotazione.nomeInfermierePrenotato = `${infermiereSelezionato!.nome} ${infermiereSelezionato!.cognome}`;
        newPrenotazione.indirizzo = indirizzo;
        //newPrenotazione.prezzoTotale = prenotazione.prezzoTotale;
        newPrenotazione.durata = servizioInfermiereSelezionato!.durata;
        newPrenotazione.noteAppuntamento = servizioInfermiereSelezionato!.noteAppuntamento;
        //newPrenotazione.note = prenotazione.note
        newPrenotazione.data = dataOraFormattata;
        newPrenotazione.checkboxPrivacy = true;
        newPrenotazione.createdByAdmin = true;
        if (!newPrenotazione.radioButtonChiPrenota) {
            newPrenotazione.radioButtonChiPrenota = "0";
        }
        setPrenotazione(newPrenotazione);
        
        try {
            const data = await ApiPrenotazioni.inviaPrenotazioneAdmin(newPrenotazione);
            if (!data) {
                toast.error("Ci dispiace. C'è stato un problema tecnico.");
            } else {
                navigate('/prenotazioni-admin?t=0&m=success');
            }
        } catch(error) {
            toast.error("Ci dispiace. C'è stato un problema tecnico.");
        }
        
        setLoading(false);

    }

    useEffect(() => {
        if(!infermieriFetched) {
            getElencoInfermieri();
        }

        if(infermiereSelezionato && !serviziInfermiereFetched) {
            getElencoServiziInfermiere();
        }

        if (prenotazione && prenotazione.utentePrenotante && prenotazione.utentePrenotante.email && prenotazione.utentePrenotante.email !== '' &&
            prenotazione.utentePrenotante.nome && prenotazione.utentePrenotante.nome !== '' && prenotazione.utentePrenotante.cognome && prenotazione.utentePrenotante.cognome !== '' && 
            prenotazione.utentePrenotante.telefono && prenotazione.utentePrenotante.telefono !== '' && prenotazione.prezzoTotale && indirizzo) {
                setSaveEnabled(true);
        }
        else {
            setSaveEnabled(false);
        }
    });

    return(
        <div className="page-template">
            <div className='main-content add-prenotazioni'>
                <h2>Nuova prenotazione</h2>
                <div className='button-container'>
                    <a onClick={() => navigate(-1)}>Indietro</a>
                </div>
                <div className='flex-container-row'> 
                    <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                        <FormHelperText>Infermiere</FormHelperText>
                        <MuiAutocomplete
                            className="autocomplete"
                            options={ elencoInfermieri }
                            value={ infermiereSelezionato || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                            getOptionLabel={ (option) => `${option.nome} ${option.cognome}` || ""}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => handleCambioInfermiere(value)} />
                    </FormControl>
                    <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                        <FormHelperText>Servizio</FormHelperText>
                        <MuiAutocomplete
                            disabled={infermiereSelezionato ? false : true}
                            className="autocomplete"
                            options={ elencoServiziInfermiere }
                            value={ servizioInfermiereSelezionato || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                            getOptionLabel={ (option) => option.nomeServizio || ""}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => handleCambioServizioInfermiere(value)} />
                    </FormControl>
                </div>
                <div className='flex-container-row'> 
                    <FormControl className='form-field' variant="outlined" style={{width: 615}}>
                        <FormHelperText>Data e ora</FormHelperText>
                        <div className='flex-container-row'> 
                            <Button style={{width: '50%'}} disabled={servizioInfermiereSelezionato ? false : true} variant="contained" onClick={() => setOpenDialogCalendar(true)}>Scegli data/ora</Button>
                            <p style={{display: dataOraPrenotazione? 'block': 'none', width: '50%'}}>{dataOraPrenotazione}</p>
                        </div>
                    </FormControl>
                </div>

                <div style={{display: dataOraPrenotazione ? 'block' : 'none'}}>
                    <div className='flex-container-row'> 
                        <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                            <FormHelperText>Prezzo</FormHelperText>
                            <OutlinedInput
                                type="number"
                                value={prenotazione?.prezzoTotale || ''}
                                onChange={handleChange('prezzoTotale')}
                                inputProps={{
                                    step: "0.01",
                                    min: servizioSelezionato?.prezzoMin || 0,
                                    max: servizioSelezionato?.prezzoMax || 9999
                                }}
                                onBlur={handleBlur}
                                endAdornment={<InputAdornment position="end">€</InputAdornment>} />
                        </FormControl>
                        <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                            <FormHelperText>Indirizzo</FormHelperText>
                                <Autocomplete
                                    className='maps-autocomplete'
                                    options={{
                                    types: ["geocode", "establishment"],
                                    componentRestrictions: { country: "it" },
                                    }}
                                    defaultValue={indirizzo || ""}
                                    apiKey={Constants.googleMaps.apiKey}
                                    onPlaceSelected={(place) => {
                                        setIndirizzo(place.formatted_address);
                                    }}
                            />
                        </FormControl>
                    </div>
                    <div className="riga-messaggio">
                        <p>{`Min: ${servizioSelezionato?.prezzoMin || "non definito"} - Max: ${servizioSelezionato?.prezzoMax || "non definito"}`}</p>
                    </div>
                    <div className='flex-container-row'> 
                        <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                            <FormHelperText>Nome paziente</FormHelperText>
                            <OutlinedInput
                                value={prenotazione?.utentePrenotante?.nome || ''}
                                onChange={handleChange('utentePrenotante.nome')} />
                        </FormControl>
                        <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                            <FormHelperText>Cognome paziente</FormHelperText>
                            <OutlinedInput
                                value={prenotazione?.utentePrenotante?.cognome || ''}
                                onChange={handleChange('utentePrenotante.cognome')} />
                        </FormControl>
                    </div>

                    <div className='flex-container-row'> 
                        <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                            <FormHelperText>Email paziente</FormHelperText>
                            <OutlinedInput
                                value={prenotazione?.utentePrenotante?.email || ''}
                                onChange={handleChange('utentePrenotante.email')} />
                        </FormControl>
                        <FormControl className='form-field' variant="outlined" style={{width: 300}}>
                            <FormHelperText>Telefono paziente</FormHelperText>
                            <OutlinedInput
                                value={prenotazione?.utentePrenotante?.telefono || ''}
                                onChange={handleChange('utentePrenotante.telefono')} />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl className='form-field' variant="outlined" style={{width: 615}}>
                            <FormHelperText>Note paziente</FormHelperText>
                            <OutlinedInput
                                multiline
                                rows='4'
                                value={prenotazione?.note || ''}
                                onChange={handleChange('note')} />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Non inviare email di notifica ad infermiere e paziente."
                                    labelPlacement="end"
                                    value={prenotazione?.nonInviareEmail}
                                    checked={prenotazione?.nonInviareEmail || false}
                                    onChange={handleNonInviareEmail}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                    
                    <div className="save-button-row">
                        <Button disabled={!saveEnabled} variant="contained" onClick={() => onSave()}>Salva</Button>
                    </div>
                </div>
                
                <DialogCalendarioAdmin open={openDialogCalendar} onClose={closeDialogCalendar} dataSelected={selezionaDataOra}
                                    infermiereSelezionato={infermiereSelezionato} nomeServizio={servizioInfermiereSelezionato?.nomeServizio} 
                                    primaDisponibilita={new Date()} durata={servizioInfermiereSelezionato?.durata}
                                    idServizio={servizioInfermiereSelezionato?.idServizio} />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <LoadingView
                    visible={loading}
                />
            </div>
        </div>
    );
}

export default AddPrenotazioniAdmin;