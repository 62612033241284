import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";

export default class Utenti {
    static async getMe() {
        try {
            const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/utenti/me/me', config);
            return response.data;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }
    }
}