import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import { InfermiereInfo } from "../../models/InfermiereInfo";
import { cloneDeep, set } from "lodash";
import DropdownProvincie from "../generics/DropdownProvincie";

interface ComponentProps {
    utente?: InfermiereInfo;
    errorRagioneSociale: boolean;
    errorVia: boolean;
    errorCap: boolean;
    errorCitta: boolean;
    errorProvincia: boolean;
    errorCodiceFiscale: boolean;
    errorPartitaIva: boolean;
    errorSdi: boolean
    onChange(utente: InfermiereInfo): void;
}
export type Props = ComponentProps;


function DatiFatturazione(props: Props) {
    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const user = cloneDeep(props.utente);
        if(user) {
            set(user, field, event.target.value);
            props.onChange(user);
        }
    };
      
    
    const handleProvinciaChange = (value: string | null) => {
        const user = cloneDeep(props.utente);
        if(user) {
            set(user, 'datiFatturazione.provincia', value);
            props.onChange(user);
        }
    }

    return (
        <div className="due-colonne">
              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Nome cognome / Ragione sociale *</FormHelperText>
                <OutlinedInput
                  error={props.errorRagioneSociale}
                  value={props.utente?.datiFatturazione?.nome || ''}
                  onChange={handleChange('datiFatturazione.nome')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Indirizzo *</FormHelperText>
                <OutlinedInput
                  error={props.errorVia}
                  value={props.utente?.datiFatturazione?.indirizzo || ''}
                  onChange={handleChange('datiFatturazione.indirizzo')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>CAP *</FormHelperText>
                <OutlinedInput
                  error={props.errorVia}
                  value={props.utente?.datiFatturazione?.cap || ''}
                  onChange={handleChange('datiFatturazione.cap')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Città *</FormHelperText>
                <OutlinedInput
                  error={props.errorCitta}
                  value={props.utente?.datiFatturazione?.citta || ''}
                  onChange={handleChange('datiFatturazione.citta')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Provincia *</FormHelperText>
                <DropdownProvincie
                  provincia={props.utente?.datiFatturazione?.provincia || "AG"} 
                  onChangeProvincia={(value) => handleProvinciaChange(value || "AG")}
                />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Codice Fiscale *</FormHelperText>
                <OutlinedInput
                  error={props.errorCodiceFiscale}
                  value={props.utente?.datiFatturazione?.codiceFiscale || ''}
                  onChange={handleChange('datiFatturazione.codiceFiscale')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Parita IVA *</FormHelperText>
                <OutlinedInput
                  error={props.errorPartitaIva}
                  value={props.utente?.datiFatturazione?.partitaIva || ''}
                  onChange={handleChange('datiFatturazione.partitaIva')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>SDI/PEC *</FormHelperText>
                <OutlinedInput
                  error={props.errorSdi}
                  value={props.utente?.datiFatturazione?.sdi || ''}
                  onChange={handleChange('datiFatturazione.sdi')} />
              </FormControl>
        </div>
    );
}

export default DatiFatturazione;