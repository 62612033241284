import Axios from "axios"
import { Servizio } from '../models/Servizio';
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";

export default class Servizi {

    
    static async getSingoloServizio (idServizioSelezionato: string) {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/servizi/' + idServizioSelezionato);
        return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

    static async getElencoServiziAttivi() {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/servizi/attivi');
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    //admin
    static async getElencoServizi(filtri?: any, page: number = 1, limit: number = 10) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let filters = "";
        let fields = "";

        if(filtri) {
            if(filtri.nome) {
                filters += `filter=${filtri.nome}`;
                fields += `fields=nome`;
            }
        }

        try {
            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/servizi?page=${page}&limit=${limit}&${filters}&${fields}`, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    static async addServizio(servizio: Servizio) {
        const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const response = await Axios.post(Constants.apiWebService.baseUrl + '/servizi', servizio, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
      }
    
      static async updateServizio(servizio: Servizio) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const response = await Axios.patch(Constants.apiWebService.baseUrl + '/servizi/' + servizio._id, servizio, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
      }
    
      static async deleteServizio(servizio: Servizio) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const response = await Axios.delete(Constants.apiWebService.baseUrl + '/servizi/' + servizio._id, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
      }

      static async algoliaReindex() {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/servizi/reindex', config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

}