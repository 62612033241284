import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Prenotazione } from '../models/Prenotazione';
import ApiPrenotazioni from '../api/Prenotazioni';
import PrenotazioneInfoForm from '../components/prenotazioni/PrenotazioneInfoForm';
import LoadingView from '../components/generics/LoadingView';
import '../style/styleComponents/PrenotazioniInfo.css';
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Button } from '@mui/material';

function PrenotazioneInfo() {
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState<String>();
    const [prenotazione, setPrenotazione] = useState<Prenotazione>();

    dayjs.extend(utc);
    dayjs.extend(timezone);
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);

    const navigate = useNavigate();


    const getPrenotazione = async (idPrenotazione: string | null) => {
        if(idPrenotazione !== null) {
            const pren: Prenotazione = await ApiPrenotazioni.getSingolaPrenotazione(idPrenotazione);
            setPrenotazione(pren);
        }
    }

    const modificaPrenotazione = () => {
        if(prenotazione && prenotazione._id) {
            navigate(`/modifica-prenotazione?id=${prenotazione._id}`);
        }
    }


    const cancellaPrenotazione = async () => {
        setLoading(true);
        if(prenotazione && prenotazione._id) {
            const pren: Prenotazione = await ApiPrenotazioni.annullaPrenotazione(prenotazione._id);
            setPrenotazione(pren);
        }
        setLoading(false);
    }


    useEffect(() => {
        const id = queryParameters.get("id");
        const t = queryParameters.get("t");
        if(t) 
            setTab(t);

        if(!prenotazione) {
            getPrenotazione(id);
        }
        
    })

    return(
        <div className="page-template">
            <div className='main-content prenotazione-info'>
                <div className="titolo-pagina">Info Prenotazione</div>
                <div className='button-container'>
                    <a onClick={() => navigate(`/prenotazioni?t=${tab}`)}>Indietro</a>  
                </div>
                {(prenotazione && prenotazione.stato !== 0 && dayjs(prenotazione.data).tz("Europe/Rome") > dayjs().tz("Europe/Rome")) ? 
                    <Button variant="contained" onClick={modificaPrenotazione}>Modifica prenotazione</Button> : ""
                }
                <PrenotazioneInfoForm prenotazione={prenotazione} />
                {(prenotazione && prenotazione.stato !== 0 && dayjs(prenotazione.data).tz("Europe/Rome") > dayjs().tz("Europe/Rome")) ? 
                    <Button variant="contained" color="error" onClick={cancellaPrenotazione}>Cancella prenotazione</Button> : ""
                }
            </div>
            <LoadingView
                visible={loading}
            />
        </div>
    );
}

export default PrenotazioneInfo;