import { useEffect, useState } from "react";
import '../../style/styleComponents/admin/CalendarioAdmin.css';
import { GiornoCalendario, Slot } from "../../models/GiornoCalendario";
import ApiInfermieri from '../../api/Infermieri';
import { InfermiereInfo } from "../../models/InfermiereInfo";
import LavoraDate from '../../utils/LavoraDate';
import { InfermiereServizio } from "../../models/InfermiereServizio";
import LoadingView from "../generics/LoadingView";
import React from "react";
import { useHistoryState } from "../../utils/useHistoryState";
import { Cart } from "../../models/Cart";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'
import ApiInfermieriServizi from "../../api/InfermieriServizi";

interface ComponentProps {
    infermiereSelezionato?: InfermiereInfo;
    primaDisponibilita?: Date;
    durata?: number;
    idServizio?: string;
    slotOrarioSelezionato?: Date;
    onChangeOrario(slotOra?:Date, dataFormattata?: string): void; 
}
export type Props = ComponentProps;


function CalendarioAdmin (props: Props) {
    const [elencoGiorniCalendario, setElencoGiorniCalendario] = useState<GiornoCalendario[]>(); 
    const [GiornoCalendario, setGiornoCalendario] = useState<GiornoCalendario>(); 
    const [dataGiornoCalendario, setDataGiornoCalendario] = useState<Date>();
    const [loading, setLoading] = React.useState(false);
    const [cart, setCart] = useHistoryState<Cart>("cart", {});
    dayjs.extend(utc);
    dayjs.extend(timezone);


    const emptySlotList = (giorno: Date) => {
        const slotDuration = 15;
        const slotNumber = 96;
        const slots: Slot[] = [];
        //const ora = dayjs("2023-06-01T23:00:00.000Z").tz("Europe/Rome"); //Mettendo la data del 01/01, devo mettere un'ora indietro poiché Roma è GMT+1
        let ora = dayjs(giorno);
        ora = ora.hour(0).minute(0).second(0).millisecond(0);
        for (let i = 0; i < slotNumber; i++) {
            let slotTime = ora.add(i*slotDuration, 'minutes');
            slots.push({
                orario: slotTime.toDate(),
                disponibilita: 0,
                prenotazioni: [],
            });
        }

        return slots;
    }

    const riempiCalendario = (arrayGiorni: GiornoCalendario[]) => {
        const oggi = new Date();
        let mAvanti = new Date(oggi);
        mAvanti.setMonth(mAvanti.getMonth() + 1);
        const unMeseInAvanti = new Date(arrayGiorni.length > 0 ? arrayGiorni[arrayGiorni.length - 1].data! : mAvanti);
      
        const giorniMancanti = [];
      
        // Creazione di un set di date già presenti nell'array
        const datePresenti = new Set(arrayGiorni.map(giorno => new Date(giorno.data!).toDateString()));
      
        // Iterazione attraverso le date da oggi a un mese in avanti
        let dataCorrente = new Date(oggi);
        while (dataCorrente <= unMeseInAvanti) {
          const dataString = dataCorrente.toDateString();
      
          // Verifica se la data è già presente nell'array
          if (!datePresenti.has(dataString)) {
            // Se la data non esiste, crea un nuovo oggetto GiornoCalendario e inseriscilo nell'array
            giorniMancanti.push({
              idUtente: props.infermiereSelezionato?.idUtente,
              data: new Date(dataCorrente),
              slots: emptySlotList(dataCorrente) // Puoi impostare altri campi a tuo piacimento
            });
          }
      
          // Passa alla data successiva
          dataCorrente.setDate(dataCorrente.getDate() + 1);
        }
      
        // Unisci gli array originali con i giorni mancanti
        const calendarioCompleto = [...arrayGiorni, ...giorniMancanti];
        
        // Ordina l'array in base al campo "data" crescente
        calendarioCompleto.sort((a, b) => new Date(a.data!).getTime() - new Date(b.data!).getTime());
        return calendarioCompleto;
      }

    //PRENDO TUTTI I GIORNI CALENDARIO DELL'INFERMIERE E LI LAVORO
    const giorniCalendarioInfermiere = async (idInfermiere : string, giorno : Date) => {
        setLoading(true);
        let tuttiGiorni : GiornoCalendario[] = await ApiInfermieri.getGiorniCalendarioInfermiereAdmin(idInfermiere, giorno);
        let infoServizioInfermiere: InfermiereServizio = {};
        if (props.infermiereSelezionato && props.infermiereSelezionato.idUtente && props.idServizio) {
            infoServizioInfermiere = await ApiInfermieriServizi.getInfoServizioInfermiereAdmin(props.infermiereSelezionato.idUtente, props.idServizio);
        }

        let arrayGiorni : GiornoCalendario[] = []; 
        
        //Riempio l'array tuttiGiorni, con i giorni mancanti a database.
        tuttiGiorni = riempiCalendario(tuttiGiorni);
        //

        //Loop sui giorni
        tuttiGiorni.forEach(function(singoloGiorno, index, object) {
            //Entro sempre tranne se è giornoNo:
            if ( !infoServizioInfermiere.giorniNo || !infoServizioInfermiere.giorniNo!.includes(dayjs(singoloGiorno.data!).tz("Europe/Rome").day()) ) {  
                let arraySlot : Slot[] = [];
                let countDurata : number = 0;
                let primoSlot : Slot = {};
                //Loop su ogni slot del singolo giorno:
                singoloGiorno.slots?.forEach(function(slot, index, object) {
                    const orarioSlot = dayjs(slot.orario!).tz("Europe/Rome");
                    if(orarioSlot < dayjs(props.primaDisponibilita).tz("Europe/Rome")) 
                        return;

                    //Se c'è fasciaOrariaNo nel servizio:
                    if (infoServizioInfermiere.fasciaOrariaNo && infoServizioInfermiere.fasciaOrariaNo.orarioInizio && infoServizioInfermiere.fasciaOrariaNo.orarioFine) {
                        const orarioInizioFasciaNo = dayjs(infoServizioInfermiere.fasciaOrariaNo!.orarioInizio!).tz("Europe/Rome");
                        const orarioFineFasciaNo = dayjs(infoServizioInfermiere.fasciaOrariaNo!.orarioFine!).tz("Europe/Rome");
                        if ( (tempo(orarioSlot.hour(), orarioSlot.minute(), 0) < tempo(orarioInizioFasciaNo.hour(), orarioInizioFasciaNo.minute(), 0) || 
                            tempo(orarioSlot.hour(), orarioSlot.minute(), 0) >= tempo(orarioFineFasciaNo.hour(), orarioFineFasciaNo.minute(), 0))
                            && (slot.disponibilita! > 0 || !slot.prenotazioni || slot.prenotazioni.length === 0)) {
                            //Agglomero gli slot in base a durata:
                            countDurata++;
                            if (countDurata >= props.durata!) {
                                arraySlot.push( singoloGiorno.slots![index - (props.durata! -1) ]);
                            }   
                        }
                        else { 
                            primoSlot = {};
                            countDurata = 0;
                        }
                    }
                    //Se non c'è FasciaOrariaNo nel servizio:
                    else {
                        if ( slot.disponibilita! > 0 || !slot.prenotazioni || slot.prenotazioni.length === 0 ) {
                            //Agglomero gli slot in base a durata:
                            countDurata++;
                            if (countDurata >= props.durata!) {
                                arraySlot.push( singoloGiorno.slots![index - (props.durata! -1) ]);
                            }   
                        }
                        else { 
                            primoSlot = {};
                            countDurata = 0;
                        }
                    }
                });
                //Se ho almeno uno slot, allora pusho il giorno nel nostro array:
                if (arraySlot.length > 0) {
                    singoloGiorno.slots = arraySlot;
                    arrayGiorni.push(singoloGiorno);
                }

            }
        })

        
        setElencoGiorniCalendario(arrayGiorni);
        console.log(arrayGiorni);

        //Prendo il giorno e lo metto in giornoCalendario
        var giornoData = dayjs(giorno).tz("Europe/Rome");
        var giornoData2 = giornoData.toDate().toDateString();
        const filteredDates = arrayGiorni.filter(d => dayjs(d.data!).tz("Europe/Rome").toDate().toDateString() === giornoData2);
        setGiornoCalendario(filteredDates[0]);
        setDataGiornoCalendario(props.primaDisponibilita);     
        setLoading(false);
    }


    const tempo = (ora: number, minuti: number, secondi: number) => {
        let slotTime = ora * 3600000 + minuti * 60000 + secondi * 1000;
        return slotTime;
    }



    const precedente = () => {
        var giornoData = dayjs(dataGiornoCalendario!).tz("Europe/Rome");
        var giornoData2 = giornoData.toDate().toDateString();
        const filteredDates = elencoGiorniCalendario!.findIndex(d => dayjs(d.data!).tz("Europe/Rome").toDate().toDateString() === giornoData2);
        const nuovo = elencoGiorniCalendario![filteredDates-1];
        if (nuovo) {
            setGiornoCalendario(nuovo);
            setDataGiornoCalendario(nuovo.data);
        }
    }

    const successivo = () => {
        var giornoData = dayjs(dataGiornoCalendario!).tz("Europe/Rome");
        var giornoData2 = giornoData.toDate().toDateString();
        const filteredDates = elencoGiorniCalendario!.findIndex(d => dayjs(d.data!).tz("Europe/Rome").toDate().toDateString() === giornoData2);
        const nuovo = elencoGiorniCalendario![filteredDates+1];
        if (nuovo) {
            setGiornoCalendario(nuovo);
            setDataGiornoCalendario(nuovo.data);
        }
    }



    useEffect(() => {
        //A monte solo la prima volta, lavori tutti i giorni e  relativi slot:
        if (!elencoGiorniCalendario || elencoGiorniCalendario.length == 0) {
            if (props.infermiereSelezionato && props.infermiereSelezionato.idUtente && props.primaDisponibilita) { 
                giorniCalendarioInfermiere(props.infermiereSelezionato.idUtente, props.primaDisponibilita); 
            }
        }
    }, [elencoGiorniCalendario]);

    

    const dataGiorno = () =>{
        if (dataGiornoCalendario) {
            return LavoraDate.lavoraDataConMese(dataGiornoCalendario);
        }   
    }

    const mostraOraSlot = (dataInput: Date) => {
        return LavoraDate.prendeOrario(dataInput);
    }

    //Scrivo la data nel local storage:
    const selezioneOrario = (date: Dayjs, orario: Date) => {
        const ddd = date.toDate().toLocaleString("en-US", {timeZone: "Europe/Rome"});
        const data = new Date(ddd);

        const dataFormattata = LavoraDate.lavoraDataPerApi(data);

        props.onChangeOrario(data, dataFormattata);

        
    }

    function getClassNames(item: Slot) {
        const formattedDate = item && item.orario ? new Date(item.orario) : null;  
        return formattedDate && formattedDate.getTime() === props.slotOrarioSelezionato?.getTime()
          ? 'slot-element-selected slot-element pointer'
          : 'slot-element pointer';
    }

    return (
        <div className="calendario-frontend-esterno">
            <div className="rigaApice">
              <div className="rigaApiceInterna">
                <div className="colonna1 pointer" onClick={precedente}> {"<"} Precedente</div>
                <div className="colonna2">{dataGiorno() || "no data"}</div>
                <div className="colonna3 pointer" onClick={successivo}>Successivo {">"}</div>
              </div>
            </div>

            <div className="slots-container-esterno">
                {GiornoCalendario?.slots?.map((item, index) => ( 
                    <div key={index} className={getClassNames(item)} onClick={()=>item && item.orario ? selezioneOrario(dayjs(item.orario).tz("Europe/Rome"), item.orario) : null}>
                        <div className='slot-orario'>{item && item.orario ? mostraOraSlot(item.orario) : null}</div>
                    </div>
                )) || ""}
            </div>

            <LoadingView
                visible={loading}
            />

        </div>
    );
}
export default CalendarioAdmin;