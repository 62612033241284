const Constants = {
    apiWebService:{
      baseUrl: process.env.REACT_APP_BASEURL
    },
    frontend:{
      url: process.env.REACT_APP_FRONTEND
    },
    backoffice: {
      url: process.env.REACT_APP_BACKOFFICE
    },
    azureContainer: {
      url: process.env.REACT_APP_AZURE_CONTAINER
    },
    algolia: {
      appId: process.env.REACT_APP_ALGOLIA_ID,
      apiKey: process.env.REACT_APP_ALGOLIA_KEY,
      Servizi: 'Servizi',
    },
    googleMaps: {
      apiKey: process.env.REACT_APP_GOOGLEMAPS_KEY
    }
  };
  
  export default Constants;