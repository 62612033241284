import { Autocomplete as MuiAutocomplete, Box, Button, FormControl, FormHelperText, OutlinedInput, Slider, TextField } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import React, { useEffect, useState } from "react";
import ApiStrutture from '../../api/Strutture';
import { InfermiereInfo } from "../../models/InfermiereInfo";
import { AddressGoogle } from '../../models/AddressGoogle';
import Constants from "../../utils/Constants";
import { Struttura } from "../../models/Struttura";
import { cloneDeep, set } from "lodash";
import PasswordField from "../generics/PasswordField";

interface ComponentProps {
    onChange(vecchiaPassword: string, nuovaPassword: string): void;
}
export type Props = ComponentProps;

function CambioPassword(props: Props) {
    const [vecchiaPassword, setVecchiaPassword] = useState("");
    const [nuovaPassword, setNuovaPassword] = useState("");

    const handleChangeOld = (value: string) => {
        setVecchiaPassword(value);
    };

    const handleChangeNew = (value: string) => {
        setNuovaPassword(value);
    };

    const handleChangePassword = () => {
        props.onChange(vecchiaPassword, nuovaPassword);
    }

    
    return (
        <div className="tre-colonne">
            <FormControl className='form-field' variant="outlined">
                <FormHelperText>Vecchia Password</FormHelperText>
                <PasswordField
                    onChange={handleChangeOld}
                />
            </FormControl>

            <FormControl className='form-field' variant="outlined">
                <FormHelperText>Nuova Password</FormHelperText>
                <PasswordField
                    onChange={handleChangeNew}
                />
            </FormControl>
            
            <div className='button-column'>
                <Button variant="contained" onClick={handleChangePassword}>Cambia</Button>
            </div>
        </div>
    );
}

export default CambioPassword;