import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import LoadingView from "../generics/LoadingView";
import { InfermiereServizio } from "../../models/InfermiereServizio";
import { Servizio } from "../../models/Servizio";
import ApiServizi from "../../api/Servizi";
import ApiInfermieriServizi from "../../api/InfermieriServizi";
import { cloneDeep, set } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import GiornoCalendarioUtils from "../../utils/GiornoCalendarioUtils";
import '../../style/styleComponents/DialogServizioInfermiere.css';
import LavoraDate from "../../utils/LavoraDate";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

interface Giorno {
    nome: string;
    val: number
}

interface ComponentProps {
    open?: boolean;
    onClose?(open:boolean): void;
    onSave?(open:boolean): void;
    servizio?: Servizio;
}
export type Props = ComponentProps;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  

function BootstrapDialogTitle(props: DialogTitleProps) {
const { children, onClose, ...other } = props;


return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
        </IconButton>
    ) : null}
    </DialogTitle>
);
}


function DialogServizioAdmin(props: Props) {

    const [loading, setLoading] = useState(false);
    const [servizioSelezionato, setServizioSelezionato] = useState<Servizio>();
    const [editMode, setEditMode] = useState(false);
    


    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const serv = cloneDeep(servizioSelezionato);
        if(serv) {
            let value : any = event.target.value;
            if(field === "prezzoMin" || field === "prezzoMin")
                value = parseFloat(value);


            set(serv, field, value);
            setServizioSelezionato(serv);
        }
      };

    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        const serv = cloneDeep(servizioSelezionato);
        if(serv) {
          set(serv, 'tipologia', parseInt(event.target.value?.toString() || "0"));
          setServizioSelezionato(serv);
        }
    }

    const handleStatoChange = (event: SelectChangeEvent<number>) => {
        const serv = cloneDeep(servizioSelezionato);
        if(serv) {
          set(serv, 'attivo', event.target.value === 1);
          setServizioSelezionato(serv);
        }
    }
    
    const handleClose = () => {
        setServizioSelezionato(undefined);
        props.onClose!(false);
    };


    const handleSalvaButton = async () => {
        setLoading(true);
        if(servizioSelezionato) {
            if(editMode) {
                try {
                    await ApiServizi.updateServizio(servizioSelezionato);
                    toast.success('Servizio aggiornato con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            } else {
                try {
                    await ApiServizi.addServizio(servizioSelezionato);
                    toast.success('Servizio aggiunto con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            }
        }
        setServizioSelezionato(undefined);
        props.onSave!(false);
        setLoading(false);
    }

    useEffect(() => {
        if(!servizioSelezionato) {
            if(!props.servizio) {
                setServizioSelezionato({nome: "",
                                        tipologia: 0,
                                        prezzoMin: 0,
                                        prezzoMax: 0,
                                        attivo: true});
                setEditMode(false);
            } else {
                setServizioSelezionato(props.servizio);
                setEditMode(true);
            }
        }
    })

    useEffect(() => {
        if(props.servizio) {
            setServizioSelezionato(props.servizio);
            setEditMode(true);
        }
    }, [props.servizio])

    return (
        <div>
        <BootstrapDialog
            className="dialog-servizio-infermiere"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open!}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Configura il servizio
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <div className="riga-due-colonne">
                    <FormControl className='form-field' variant="outlined" hidden={!editMode}>
                        <FormHelperText>Id Servizio</FormHelperText>
                        
                        <OutlinedInput
                            disabled
                            value={servizioSelezionato?._id || ''}
                            />
                    </FormControl>
                    <FormControl className='form-field' variant="outlined" hidden={!editMode}>
                        <FormHelperText>Stato</FormHelperText>
                        <Select
                            value={servizioSelezionato?.attivo ? 1 : 0 || 0}
                            onChange={handleStatoChange}
                            >
                                <MenuItem value={0}>Non attivo</MenuItem>
                                <MenuItem value={1}>Attivo</MenuItem>
                            </Select>
                    </FormControl>
                </div>

                <div className="riga-due-colonne">
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Nome</FormHelperText>
                            <OutlinedInput
                                value={servizioSelezionato?.nome || ""}
                                onChange={handleChange('nome')} 
                            />
                        </FormControl>
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Tipologia</FormHelperText>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={servizioSelezionato?.tipologia || 0}
                                onChange={handleSelectChange}
                            >
                                <MenuItem value={0}>A domicilio</MenuItem>
                                <MenuItem value={1}>In studio</MenuItem>
                            </Select>
                        </FormControl>
                </div>

                <div className="riga-due-colonne">
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Prezzo Minimo</FormHelperText>
                            <OutlinedInput
                                type="number"
                                value={servizioSelezionato?.prezzoMin || ""}
                                onChange={handleChange('prezzoMin')} 
                                inputProps={{
                                    step: "0.1",
                                }}
                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                />
                        </FormControl>
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Prezzo Massimo</FormHelperText>
                            <OutlinedInput
                                type="number"
                                value={servizioSelezionato?.prezzoMax || ""}
                                onChange={handleChange('prezzoMax')} 
                                inputProps={{
                                    step: "0.1",
                                }}
                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                />
                        </FormControl>
                </div>

                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Descrizione</FormHelperText>
                        <OutlinedInput
                            value={servizioSelezionato?.descrizione || ""}
                            multiline
                            rows={3}
                            onChange={handleChange('descrizione')} 
                            />
                    </FormControl>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSalvaButton} disabled={false /*slotOrarioSelezionato ? false : true*/}>Salva</Button>
            </DialogActions>
        </BootstrapDialog>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />

        <LoadingView
                visible={loading}
        />

        </div>
    );
}
export default DialogServizioAdmin;