import { useEffect, useState } from "react";
import { Fattura } from "../../models/Fattura";
import { Autocomplete, Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ApiFatture from '../../api/Fatture';
import ApiInfermieri from '../../api/Infermieri';
import Cookies from "js-cookie";
import { cloneDeep, set } from "lodash";
import '../../style/stylePages/Fatture.css';
import { ToastContainer } from "react-toastify";
import CreaPDF from "../../utils/CreaPDF";


function FattureAdmin() {
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta fattura
    const [utente, setUtente] = useState<any>();
    const [fatture, setFatture] = useState<Fattura[]>();
    const [elencoAnni, setElencoAnni] = useState<number[]>([]);
    const [annoSelezionato, setAnnoSelezionato] = useState<number>();
    const [filtri, setFiltri] = useState({periodo: undefined, numero: undefined, infermiere: undefined, struttura: undefined});
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);

    const ANNO_INIZIO = 2023;


    const getInfermiereInfo = async (idInfermiere: string) => {
        const data = await ApiInfermieri.getSingoloInfermiere(idInfermiere);
        return data;
    }

    const getFatture = async (page: number, limit: number = 10) => {
        const data = await ApiFatture.getElencoFattureAdmin(annoSelezionato, filtri, page, limit);
        if(data) {
            setFatture(data.docs);
            setTotalPages(data.totalPages);
        }
        else 
            setFatture([]);
    }

    const handleCambioAnno = (value: number | null) => {
        if(value) {
            setAnnoSelezionato(value);
            setFatture(undefined);
        }
    }

    const getElencoAnni = () => {
        const anni = [];
        for(let data = new Date().getFullYear(); data >= ANNO_INIZIO; data--) {
            anni.push(data);
        }
        setElencoAnni(anni);
        setAnnoSelezionato(anni[0]);
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = cloneDeep(filtri);
        set(f, field, event.target.value);
        setFiltri(f);
        setFatture(undefined);
    };

    //Paginazione
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
        getFatture(newPage);
    }


    useEffect(() => {
        if(!utente) {
            let authCookie = Cookies.get('auth');
            let user = {}
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;
                setUtente(user);
            }
        }
        else if(!fatture) {
            if(elencoAnni.length === 0)
                getElencoAnni();
            getFatture(page);
        }
    });
    
    return (
        <div className="page-template">
            <div className='main-content'>
                <h2>Fatture</h2>
                <div className="filtri">
                    <div className="filtro-box">
                        <Autocomplete
                            options={ elencoAnni }
                            value={ annoSelezionato || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                            onChange={(event, value) => handleCambioAnno(value)}
                            getOptionLabel={(option) =>typeof(option) == 'number'? String (option) :option}
                            renderInput={(params) => <TextField {...params} />}
                            />
                    </div>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.periodo || ''}
                            onChange={handleChange('periodo')}
                            label="Periodo" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.numero || ''}
                            onChange={handleChange('numero')}
                            label="N.Fattura" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.infermiere || ''}
                            onChange={handleChange('infermiere')}
                            label="Infermiere" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.struttura || ''}
                            onChange={handleChange('struttura')}
                            label="Struttura" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                    
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className="tabella-fatture" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Numero</TableCell>
                            {/*<TableCell>Data Fattura</TableCell>*/}
                            <TableCell>Periodo</TableCell>
                            <TableCell>Infermiere</TableCell>
                            <TableCell>Struttura</TableCell>
                            <TableCell>Incasso totale</TableCell>
                            {/*<TableCell>Commissione dovuta</TableCell>*/}
                            <TableCell>IVA</TableCell>
                            <TableCell>Totale dovuto</TableCell>
                            <TableCell>Fattura</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {fatture?.map((row: Fattura) => (
                            <TableRow
                                hover
                                key={row._id}
                                className="table-row"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.numero}</TableCell>
                                {/*<TableCell>{row.data}</TableCell>*/}
                                <TableCell>{`${row.mesePeriodoRiferimento} ${row.annoPeriodoRiferimento}`}</TableCell>
                                <TableCell>{row.infermiere?.nomeInfermiere}</TableCell>
                                <TableCell>{row.infermiere?.nomeStruttura || '-'}</TableCell>
                                <TableCell>{`${row.contabilita?.prezzoTotale?.toFixed(2)} €`}</TableCell>
                                {/*<TableCell>{`${row.contabilita?.commissioneFutura?.toFixed(2)} €`}</TableCell>*/}
                                <TableCell>22%</TableCell> 
                                <TableCell>
                                    {`${row.contabilita?.prezzoFutura ? (row.contabilita?.prezzoFutura * 1.22)?.toFixed(2) : ""} €`}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => CreaPDF.creaFatturaPdf(row)}>Scarica</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack spacing={2} className='paginazione'>
                    <Pagination count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
                </Stack>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>

    );
}

export default FattureAdmin;