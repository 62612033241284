import * as React from 'react';
import '../../style/styleComponents/LoadingView.css';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export type Props = {
    object: any;
    onEdit(object: any): void;
    onDelete(object: any): void;
    onClose(): void;
};

function ButtonMenu(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        props.onClose();
    };

    const handleEdit = () => {
        props.onEdit(props.object);
        setAnchorEl(null);
    }

    const handleDelete = () => {
        props.onDelete(props.object);
        setAnchorEl(null);
    }
    
    return (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={handleEdit}>
                Modifica
            </MenuItem>
            <MenuItem onClick={handleDelete}>
                Elimina
            </MenuItem>
          </Menu>
        </div>
    );
}

export default ButtonMenu;