import { Prenotazione } from '../../models/Prenotazione';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Box, Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import '../../style/styleComponents/TabPrenotazioni.css';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { cloneDeep, set } from 'lodash';


interface ComponentProps {
    value: Number;
    index: Number;
    isAdmin?: boolean;
    getPrenotazioni(idInfermiere: string, tipo: string, pagina: number, filtri?: any): Promise<any>;
    openDialogFunc(row: Prenotazione): void;
    editPrenotazionePage?(isNew: boolean): void;
}
export type Props = ComponentProps;

function TabPrenotazioni(props: Props) {
    const [prenotazioni, setPrenotazioni] = useState<Prenotazione[]>();
    const [utente, setUtente] = useState<any>();
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filtri, setFiltri] = useState({idInfermiere: undefined, nomeInfermiere: undefined});


    const fetchPrenotazioni = async (pagina: number = 1) => {
        let tipo = "precedenti";
        if(props.index === 0)
            tipo = "successive";

        let idInfermiere = filtri.idInfermiere || "";
        if(!props.isAdmin) {
            idInfermiere = utente._id;
        }
        const data = await props.getPrenotazioni(idInfermiere, tipo, pagina, filtri)
        if(data) {
            setPrenotazioni(data.docs);
            setTotalPages(data.totalPages)
        }
    }

    //Paginazione
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
        fetchPrenotazioni(newPage);
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = cloneDeep(filtri);
        set(f, field, event.target.value);
        setFiltri(f);
        setPrenotazioni(undefined);
    };

    useEffect(() => {
        if(!utente) {
            let authCookie = Cookies.get('auth');
            let user = {}
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;
                setUtente(user);
            }
        }

        if(!prenotazioni) {
            fetchPrenotazioni();
        }
    });


    return(
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`simple-tabpanel-${props.index}`}
            aria-labelledby={`simple-tab-${props.index}`}
            >
            <div className="filtri" style={{marginTop: 50, display: props.isAdmin ? 'flex': 'none'}}>
                <h3 style={{marginTop: 13}}>Filtri: </h3>
                <div className="filtro-box">
                    <TextField
                        value={filtri.idInfermiere || ''}
                        onChange={handleChange('idInfermiere')}
                        label="ID Infermiere" 
                        variant="outlined"
                        type="search"
                    />
                </div>
                <div className="filtro-box">
                    <TextField
                        value={filtri.nomeInfermiere || ''}
                        onChange={handleChange('nomeInfermiere')}
                        label="Nome/Cognome Infermiere" 
                        variant="outlined"
                        type="search"
                    />
                </div>
            </div>
            <div className="button-row" style={{marginTop: 50, marginBottom: 0, display: props.isAdmin ? 'flex': 'none'}}>
                <Button variant="contained" onClick={() => props.editPrenotazionePage!(true)}>Nuova prenotazione</Button>
            </div>
            {props.value === props.index && (
                <Box sx={{ p: 3 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                            <TableRow>
                                <TableCell>Data</TableCell>
                                <TableCell>Orario</TableCell>
                                <TableCell>Servizio</TableCell>
                                <TableCell>Indirizzo</TableCell>
                                <TableCell style={{ display: props.isAdmin ? 'table-cell': 'none'}}>Infermiere</TableCell>
                                <TableCell>Prenotante</TableCell>
                                <TableCell>Paziente</TableCell>
                                <TableCell>Prezzo</TableCell>
                                <TableCell>Stato</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {prenotazioni?.map((row: Prenotazione) => {
                                const dataPrestazione = dayjs(row.data!).tz("Europe/Rome")
                                const dataString = dataPrestazione.locale('it').format('DD/MM/YYYY')
                                const oraString = dataPrestazione.locale('it').format('HH:mm')

                                return (
                                    <TableRow
                                        hover
                                        key={row._id}
                                        className="table-row"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell onClick={() => props.openDialogFunc(row)}>{dataString}</TableCell>
                                        <TableCell onClick={() => props.openDialogFunc(row)}>{oraString}</TableCell>
                                        <TableCell onClick={() => props.openDialogFunc(row)}>{row.nomeServizio}</TableCell>
                                        <TableCell onClick={() => props.openDialogFunc(row)}>{row.indirizzo}</TableCell>
                                        <TableCell style={{ display: props.isAdmin ? 'table-cell': 'none'}} onClick={() => props.openDialogFunc(row)}>{row.nomeInfermierePrenotato}</TableCell>
                                        <TableCell onClick={() => props.openDialogFunc(row)}>{`${row.utentePrenotante?.nome} ${row.utentePrenotante?.cognome}`}</TableCell>
                                        <TableCell onClick={() => props.openDialogFunc(row)}>{row.paziente?.nome ? `${row.paziente?.nome} ${row.paziente?.cognome}` : `${row.utentePrenotante?.nome} ${row.utentePrenotante?.cognome}`}</TableCell>

                                        <TableCell onClick={() => props.openDialogFunc(row)}>{`${row.prezzoTotale} €`}</TableCell>
                                        <TableCell className={row.stato ? "confermata" : "cancellata"} onClick={() => props.openDialogFunc(row)}>{row.stato ? "Confermata" : "Cancellata"}</TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack spacing={2} className='paginazione'>
                        <Pagination count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
                    </Stack>
                </Box>
                )}
        </div>
    );
}

export default TabPrenotazioni;