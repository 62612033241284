import Axios from "axios"
import { Prenotazione } from '../models/Prenotazione';
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";

export default class Prenotazioni {

    static async inviaPrenotazione (prenotazione : Prenotazione) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try{
            const response =  await Axios.post(Constants.apiWebService.baseUrl + '/prenotazioni', prenotazione, config);
            return response;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message); 
        }
    }

    static async inviaPrenotazioneAdmin (prenotazione : Prenotazione) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try{
            const response =  await Axios.post(Constants.apiWebService.baseUrl + '/prenotazioni/adminCreate', prenotazione, config);
            return response;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message); 
        }
    }

    static async modificaPrenotazione (prenotazione : Prenotazione) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try{
            const response =  await Axios.patch(Constants.apiWebService.baseUrl + '/prenotazioni/' + prenotazione._id, prenotazione, config);
            return response;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message); 
        }
    }

    /*static async getElencoPrenotazioni() {
        const token = Auth.getToken();
        const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
        };

        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/prenotazioni/all');
            return response.data;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }

    } */

    static async getElencoPrenotazioniInfermierePassate(idInfermiere?: string, page: number = 1, limit?: number, filtri?: any) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let filters = "";
        let fields = "";

        if(idInfermiere) {
            filters += `${idInfermiere},`;
            fields += `idInfermierePrenotato,`;
        }

        if(filtri) {
            if(filtri.nomeInfermiere) {
                filters += `${filtri.nomeInfermiere},`;
                fields += `nomeInfermierePrenotato,`;
            }      
        }

        if(filters != '') {
            filters = filters.slice(0, -1);
        }
        if(fields != '') {
            fields = fields.slice(0, -1);
        }

        if(filters != '') {
            filters = `filter=${filters}&`
        }

        if(fields != '') {
            fields = `fields=${fields}&`
        }


        
        try {
            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/prenotazioni/passate?${filters}${fields}page=${page}&limit=${limit}`, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    static async getElencoPrenotazioniInfermiereFuture(idInfermiere?: string, page: number = 1, limit?: number, filtri?: any) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let filters = "";
        let fields = "";

        if(idInfermiere) {
            filters += `${idInfermiere},`;
            fields += `idInfermierePrenotato,`;
        }

        if(filtri) {
            if(filtri.nomeInfermiere) {
                filters += `${filtri.nomeInfermiere},`;
                fields += `nomeInfermierePrenotato,`;
            }
        }

        if(filters != '') {
            filters = filters.slice(0, -1);
        }
        if(fields != '') {
            fields = fields.slice(0, -1);
        }

        if(filters != '') {
            filters = `filter=${filters}&`
        }

        if(fields != '') {
            fields = `fields=${fields}&`
        }

        if(!page) 
            page = 1;
        
        try {
            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/prenotazioni/future?${filters}${fields}page=${page}&limit=${limit}&sort=data&order=1`, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    static async getSingolaPrenotazione(idPrenotazione: string) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/prenotazioni/' + idPrenotazione, config);
            return response.data;
            
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }
    }

    static async annullaPrenotazione(idPrenotazione: string) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await Axios.patch(Constants.apiWebService.baseUrl + '/prenotazioni/annulla/' + idPrenotazione, {id: idPrenotazione, stato: 0}, config);
            return response.data;
            
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }
    }
}