import { Autocomplete as MuiAutocomplete, Box, Button, FormControl, FormHelperText, OutlinedInput, Slider, TextField } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import React, { useEffect, useState } from "react";
import ApiStrutture from '../../api/Strutture';
import { InfermiereInfo } from "../../models/InfermiereInfo";
import { AddressGoogle } from '../../models/AddressGoogle';
import Constants from "../../utils/Constants";
import { Struttura } from "../../models/Struttura";
import { cloneDeep, set } from "lodash";

interface ComponentProps {
    utente?: InfermiereInfo;
    errorAddress: boolean;
    errorDistance: boolean;
    onChange(utente: InfermiereInfo): void;
    onChangeIndirizzo(indirizzo?: string): void
    isLoading(loading: boolean): void;
}
export type Props = ComponentProps;



function DatiUtente(props: Props) {
    const [elencoStrutture, setElencoStrutture] = useState<Struttura[]>([]);
    const [strutturaSelezionata, setStrutturaSelezionata] = useState<Struttura>();

    const setStruttura = async () => {
      if(props.utente) {
        elencoStrutture.forEach(item => {
          if(item._id === props.utente!.idStruttura) {
            setStrutturaSelezionata(item);
          }
        })
      }
    }

    const getElencoStrutture = async () => {
        const data = await ApiStrutture.getElencoStrutture();
        setElencoStrutture(data.docs);
      } 

    const handleChangeIndirizzo = (place: AddressGoogle) => {
        props.onChangeIndirizzo(place.formatted_address);
    }

    const handleChangeIndirizzoText = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(!event.target.value || event.target.value === '')
        props.onChangeIndirizzo(undefined);
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const user = cloneDeep(props.utente);
        if(user) {
            set(user, field, event.target.value);
            props.onChange(user);
        }
    };
    
    const handleElencoStrutture = (value: Struttura | null) => {
      const user = cloneDeep(props.utente);
      if(user) {
        let idStruttura = null;
        let str;
        if(value) {
          idStruttura = value?._id;
          str = value;
        }
        set(user, 'idStruttura', idStruttura);
        setStrutturaSelezionata(str);
        props.onChange(user);
      }
    };

    useEffect(() => {
        if(!elencoStrutture || elencoStrutture.length === 0) {
            getElencoStrutture();
        }
        props.isLoading(false);
    })

    useEffect(() => {
      if(!strutturaSelezionata) {
          setStruttura();
      }
      props.isLoading(false);
    })
    
    return (
        <div className="due-colonne">
              <FormControl className={props.errorAddress ? 'form-field-error' : 'form-field'} variant="outlined">
                <FormHelperText>Indirizzo Studio *</FormHelperText>
                <Autocomplete
                    className='maps-autocomplete'
                    options={{
                      types: ["geocode", "establishment"],
                      componentRestrictions: { country: "it" },
                    }}
                    defaultValue={props.utente?.indirizzo || ""}
                    apiKey={Constants.googleMaps.apiKey}
                    onPlaceSelected={(place) => {
                      handleChangeIndirizzo(place);
                    }}
                    onChange={handleChangeIndirizzoText}
                  />
              </FormControl>
              <FormControl className={props.errorDistance ? 'form-field-error' : 'form-field'} variant="outlined">
                <FormHelperText>Raggio d'azione *</FormHelperText>
                <OutlinedInput
                  error={props.errorDistance}
                  type="number"
                  inputProps={{
                    step: "0.1"
                  }}
                  value={props.utente?.distanza || ''}
                  onChange={handleChange('distanza')} />
              </FormControl>

              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Telefono</FormHelperText>
                <OutlinedInput
                  value={props.utente?.telefono || ''}
                  onChange={handleChange('telefono')} />
              </FormControl>
              
              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Struttura</FormHelperText>
                <MuiAutocomplete
                      id="combo-box-demo"
                      options={ elencoStrutture }
                      value={ strutturaSelezionata || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                      getOptionLabel={ (option) => option.nome || ""}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(event, value) => handleElencoStrutture(value)} />
              </FormControl>
              <FormControl className='form-field' variant="outlined">
                <FormHelperText>Descrizione</FormHelperText>
                <OutlinedInput
                  multiline
                  minRows={3}
                  value={props.utente?.descrizione || ''}
                  onChange={handleChange('descrizione')} />
              </FormControl>
        </div>
    );
}

export default DatiUtente;