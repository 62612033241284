import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import { blue, green, red } from '@mui/material/colors';


function BadgeGiorno(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] } & { freeDays?: number[] }) {
    const { highlightedDays = [], freeDays = [], day, outsideCurrentMonth, ...other } = props;
    
    const dayItem = props.day.date();
    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.includes(dayItem);
    
    const isFree =
      !props.outsideCurrentMonth && freeDays.includes(dayItem);
  
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '📘' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} 
          sx={{[`&&.${pickersDayClasses.selected}`]: {
                backgroundColor: 'transparent', //isFree ? green[100] : red[100],
                border: '2px solid',
                borderColor: isFree ? green[900] : red[900],
                color: 'black',
                fontWeight: 900,
              },
              [`&&.${pickersDayClasses.root}:hover`]: {
                backgroundColor: 'transparent', //isFree ? green[100] : red[100],
                border: '2px solid',
                borderColor: isFree ? green[900] : red[900],
                color: 'black',
                fontWeight: 900,
              },
              color: 'white', 
              backgroundColor: isFree ? green[900] : red[900]}}/>
      </Badge>
    );
  }

  export default BadgeGiorno;