import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import ApiPrenotazioni from '../../api/Prenotazioni';
import Cookies from "js-cookie";
import { cloneDeep } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { Prenotazione } from "../../models/Prenotazione";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import TabPrenotazioni from "../../components/prenotazioni/TabPrenotazioni";


function PrenotazioniAdmin() {
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta servizio
    const [utente, setUtente] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);  //Righe per pagina: Valore statico deciso qui!!!
    const [prenotazioniPrecedenti, setPrenotazioniPrecedenti] = useState<Prenotazione[]>();
    const [prenotazioniSuccessive, setPrenotazioniSuccessive] = useState<Prenotazione[]>();
    const [prenotazioneSelezionata, setPrenotazioneSelezionata] = useState<Prenotazione>();
    const [tabValue, setTabValue] = useState<Number>(0);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const location = useLocation()
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);


    const getPrenotazioni = async (idInfermiere: string, tipo: string, pagina: number, filtri?: any) => {
        let data;
        let id;
        if(idInfermiere !== "")
            id = idInfermiere
        if(tipo === "precedenti")
            data = await ApiPrenotazioni.getElencoPrenotazioniInfermierePassate(id, pagina, rowsPerPage, filtri);
        else
            data = await ApiPrenotazioni.getElencoPrenotazioniInfermiereFuture(id, pagina, rowsPerPage, filtri);

        let prenotazioni = {docs: [], totalPages: 1};
        if(data) {
            prenotazioni.docs = data.docs;

            prenotazioni.docs.sort((a: Prenotazione, b: Prenotazione) => {
                let dataA = new Date(a.data!);
                let dataB = new Date(b.data!);
            
                if (dataA > dataB) {
                    return -1;
                }
                if (dataA < dataB) {
                    return 1;
                }
                return 0;
            });

            prenotazioni.totalPages = data.totalPages;
        }

        return prenotazioni;
    }

    const deletePrenotazione = async (prenotazione: Prenotazione) => {
        try {
            /*const data = await ApiInfermieriServizi.deleteServizioInfermiere(infermiereServizio);
            setServizioSelezionato(undefined);
            setServizi(undefined);*/
            toast.success("Servizio cancellato con successo");
        } catch (err: any) {
            toast.error(err);
        }   
    }

    const openDialogFunc = (prenotazione?: Prenotazione) => {
        if(prenotazione) {
            setPrenotazioneSelezionata(prenotazione);
            navigate(`/prenotazione?id=${prenotazione?._id}&t=${tabValue}`);
        }
    }

    const editPrenotazionePage = (isNew: boolean) => {
        if(isNew)
            navigate(`/nuova-prenotazione?t=${tabValue}`);
    }

    const closeDialogAndRefresh = () => {
        setPrenotazioniPrecedenti(undefined);
        setPrenotazioniSuccessive(undefined);
        setPrenotazioneSelezionata(undefined);
        setOpenDialog(false);
    }

    const closeDialog = () => {
        setPrenotazioneSelezionata(undefined);
        setOpenDialog(false);
    }

    const handleChange = (event: any, newValue: Number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if(!utente) {
            const id = queryParameters.get("t");
            if(id)
                setTabValue(parseInt(id));

            const msg = queryParameters.get("m");
            if(msg && msg === 'success')
                toast.success("Operazione effettuata con successo.");

            let authCookie = Cookies.get('auth');
            let user = {}
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;
                setUtente(user);
            }
        }
    });

    
    return (
        <div className="page-template">
            <div className='main-content' style={{ visibility: !loading ? 'visible': 'hidden'}}>
                <h2>Le prenotazioni dei tuoi pazienti</h2>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Prenotazioni future" />
                    <Tab label="Prenotazioni eseguite" />
                </Tabs>
                <TabPrenotazioni 
                    value={tabValue} 
                    isAdmin={true}
                    index={0} 
                    getPrenotazioni={getPrenotazioni}
                    openDialogFunc={openDialogFunc} 
                    editPrenotazionePage={editPrenotazionePage} />
                <TabPrenotazioni 
                    value={tabValue} 
                    isAdmin={true}
                    index={1} 
                    getPrenotazioni={getPrenotazioni}
                    openDialogFunc={openDialogFunc} 
                    editPrenotazionePage={editPrenotazionePage} />
                
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>

    );
}

export default PrenotazioniAdmin;