import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DateTimePicker, LocalizationProvider, MobileDatePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/it';
import { GiornoCalendario } from "../../models/GiornoCalendario";
import { useEffect, useState } from "react";
import LavoraDate from "../../utils/LavoraDate";
import '../../style/stylePages/Calendario.css';
import BadgeGiorno from "./BadgeGiorno";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import GiornoCalendarioUtils from "../../utils/GiornoCalendarioUtils";
import LoadingView from "../generics/LoadingView";

interface ComponentProps {
    actionDateStart?: Dayjs | null;
    actionDateEnd?: Dayjs | null;
    onChangeDateStart(value: Dayjs): void;
    onChangeDateEnd(value: Dayjs): void;
    getCalendarioMensile(day: dayjs.Dayjs): Promise<GiornoCalendario[]>;
    getCalendario(day: dayjs.Dayjs): Promise<GiornoCalendario[]>;
    liberaSlot(): Promise<string>;
    liberaSingoloSlot(actionDateStart: dayjs.Dayjs, actionDateEnd: dayjs.Dayjs): Promise<string>;
    bloccaSingoloSlot(actionDateStart: dayjs.Dayjs, actionDateEnd: dayjs.Dayjs): Promise<string>;
    bloccaSlot(): Promise<string>;
    bloccaGiorno(startDate: string, endDate: string): Promise<string>;
    ripetiGiorno(giorno?: GiornoCalendario): Promise<string>;
}
export type Props = ComponentProps;

function CalendarioView (props: Props) {
    const [loading, setLoading] = useState<boolean>(true);
    const [scroll, setScroll] = useState<boolean>(true);
    const [giorno, setGiorno] = useState<dayjs.Dayjs>(dayjs(new Date()).add(1, 'day'));
    const [giornoCalendario, setGiornoCalendario] = useState<GiornoCalendario>();
    const [giorniOccupati, setGiorniOccupati] = useState<number[]>();
    const [giorniConSlotLiberi, setGiorniConSlotLiberi] = useState<number[]>();

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale('it');
    
    const oggi = dayjs(new Date()).add(1, 'day');
    
    //Trovo la lista di giorni occupati (con prenotazione) e di giorni no (senza disponibilità)
    const elaboraDisponibilitaGiorni = (calendar: GiornoCalendario[]) => {
        const listaGiorniOccupati: number[] = [];
        const listaGiorniConDisponibilita: number[] = []
        calendar.forEach(giorno => {
            giorno.slots?.forEach(slot => {
                if(slot.prenotazioni && slot.prenotazioni.length > 0) {
                    const day = new Date(giorno.data!).getDate();
                    if(!listaGiorniOccupati.includes(day)) {
                        listaGiorniOccupati.push(day);
                    }
                } 
                if(slot.disponibilita) {
                    const day = new Date(giorno.data!).getDate();
                    if(!listaGiorniConDisponibilita.includes(day)) {
                        listaGiorniConDisponibilita.push(day);
                    }
                }
            })
        })
        setGiorniOccupati(listaGiorniOccupati);
        setGiorniConSlotLiberi(listaGiorniConDisponibilita);
    }
    
    const getCalendarioMensile = async (day: dayjs.Dayjs) => {
        const data : GiornoCalendario[] = await props.getCalendarioMensile(day);
        //setCalendarioMensile(data);
        elaboraDisponibilitaGiorni(data);
    }

    const getCalendario = async (day: dayjs.Dayjs) => {
        setLoading(true);
        setGiorno(day);
        const data : GiornoCalendario[] = await props.getCalendario(day);
        if(data && data.length > 0)
            setGiornoCalendario(data[0]);
        else if(!data) //in questo caso sta aspettando che il component padre prenda l'id dell'utente dai cookie
            setGiornoCalendario(undefined);
        else //in questo caso il giorno è effettivamente vuoto
            setGiornoCalendario({});
        setLoading(false);
    }

    const cambiaGiorno = async (day: dayjs.Dayjs) => {
        setScroll(true);
        await getCalendario(day);
    }

    const selezioneOrario = async (disponibilita: string | undefined, data: dayjs.Dayjs) => {
        if(disponibilita === "occupato")
            return;

        const dataFine = data.add(15, 'minute')
        try {
            setLoading(true);
            if(!disponibilita || disponibilita === "non-disponibile")
                await props.liberaSingoloSlot(data, dataFine);
            else if(disponibilita === "disponibile")
                await props.bloccaSingoloSlot(data, dataFine);

            
            setGiornoCalendario(undefined);
            setLoading(false);
            toast.success('Disponibilità aggiornata con successo!');
        }
        catch (error) {
            setLoading(false);
            toast.error("Errore nell'aggiornamento della disponibilità!");
            console.log(error);
        }
    }

    const mostraOraSlot = (dataInput: Date) => {
        return LavoraDate.prendeOrario(dataInput);
    }

    const emptySlotList = () => {
        const slotDuration = 15;
        const slotNumber = 96;
        const slots = [];
        //const ora = dayjs("2023-06-01T23:00:00.000Z").tz("Europe/Rome"); //Mettendo la data del 01/01, devo mettere un'ora indietro poiché Roma è GMT+1
        let ora = dayjs(giorno);
        ora = ora.hour(0).minute(0).second(0).millisecond(0);
        for (let i = 0; i < slotNumber; i++) {
            let slotTime = ora.add(i*slotDuration, 'minutes');
            slots.push(slotTime);
        }
        return (
            <>
                {slots.map((item, index) => {
                    return (

                        <div key={index} className="slot-element non-disponibile" onClick={()=>item ? selezioneOrario("non-disponibile", item) : null}>
                                {item ? <span>{mostraOraSlot(item.toDate())}</span> : 
                                null}
                        </div>
                    )}) || "Non riesco a caricare gli slot"}
            </>
        );
    }

    const creaSlotList = () => {
        return (
            <>
                {giornoCalendario?.slots?.map((item, index) => {
                    let classState: string;
                    if(item && item.orario && dayjs(item.orario) < dayjs().add(1, 'day')) {
                        classState = "disabled";
                    }
                    else if(item && item.disponibilita && item.disponibilita > 0) {
                        classState = "disponibile";
                    }
                    else if (item && !item.disponibilita &&
                        item.prenotazioni && item.prenotazioni.length > 0) {
                            classState = "occupato";
                        }
                    else {
                        classState = "non-disponibile";
                    }
                    return (
                        <div key={index} className={`slot-element ${classState}`} onClick={()=>item && item.orario && dayjs(item.orario) >= dayjs().add(1, 'day') ? selezioneOrario(classState, dayjs(item.orario).tz("Europe/Rome")) : null}>
                            {item && item.orario ? <span>{mostraOraSlot(item.orario!)}</span> : 
                            null}
                        </div>
                    );
                }) || emptySlotList()}
            </>
        )
    }

    const changeDataStart = (value: Dayjs | null) => {
        if(value)
            props.onChangeDateStart(value);
    }

    const changeDataEnd = (value: Dayjs | null) => {
        if(value)
            props.onChangeDateEnd(value);
    }

    const liberaButton = async () => {
        if(!props.actionDateStart || props.actionDateStart < dayjs().tz("Europe/Rome") || !props.actionDateEnd || props.actionDateEnd < dayjs().tz("Europe/Rome")) {
            toast.error("Il range di date non può includere date o orari inferiori agli attuali");
            return;
        }
        if(props.actionDateEnd < props.actionDateStart) {
            toast.error("La data di fine non può essere precedente alla data di inizio");
            return;
        }
        try {
            setLoading(true);
            if(dayjs(props.actionDateStart.toDate()) > dayjs().add(1, 'day'))
                setGiorno(dayjs(props.actionDateStart.toDate()));
            await props.liberaSlot();
            setLoading(false);
            toast.success('Disponibilità aggiornata con successo!');
            setGiornoCalendario(undefined);
        }
        catch (error) {
            setLoading(false);
            toast.error("Errore nell'aggiornamento della disponibilità!");
            console.log(error);
        }
        
    }

    const bloccaButton = async () => {
        if(!props.actionDateStart || props.actionDateStart < dayjs(new Date()) || !props.actionDateEnd || props.actionDateEnd < dayjs(new Date())) {
            toast.error("Il range di date non può includere date o orari inferiori agli attuali");
            return;
        }
        if(props.actionDateEnd < props.actionDateStart) {
            toast.error("La data di fine non può essere precedente alla data di inizio");
            return;
        }
        try {
            setLoading(true);
            if(dayjs(props.actionDateStart.toDate()) > dayjs().add(1, 'day'))
                setGiorno(dayjs(props.actionDateStart.toDate()));
            await props.bloccaSlot();
            setLoading(false);
            toast.success('Disponibilità aggiornata con successo!');
            setGiornoCalendario(undefined);
        }
        catch (error) {
            setLoading(false);
            toast.error("Errore nell'aggiornamento della disponibilità!");
            console.log(error);
        }
        
    }

    const bloccaGiornoButton = async () => {
        if(giorno < oggi) {
            toast.error("Non è possibile compiere questa azione su una data passata");
            return;
        }

        const startDate = `${giorno.year()}-${(giorno.month()+1).toString().padStart(2,'0')}-${giorno.date().toString().padStart(2,'0')}T00:00:00`; //SLOT
        const endDate = `${giorno.year()}-${(giorno.month()+1).toString().padStart(2,'0')}-${giorno.date().toString().padStart(2,'0')}T23:59:00`; //SLOT
        try {
            setLoading(true);
            await props.bloccaGiorno(startDate, endDate);
            setLoading(false);
            toast.success('Disponibilità aggiornata con successo!');
            setGiornoCalendario(undefined);
        }
        catch (error) {
            setLoading(false);
            toast.error("Errore nell'aggiornamento della disponibilità!");
            console.log(error);
        }
    }

    const ripetiButton = async () => {
        if(giorno < oggi) {
            toast.error("Non è possibile compiere questa azione su una data passata");
            return;
        }

        if(!giornoCalendario || GiornoCalendarioUtils.esistePrenotazione(giornoCalendario)) {
            toast.error("Non puoi copiare un giorno che contiene prenotazioni");
            return;
        }
        try {
            setLoading(true);
            await props.ripetiGiorno(giornoCalendario);
            setLoading(false);
            toast.success('Disponibilità aggiornata con successo!');
            setGiornoCalendario(undefined);
        }
        catch (error) {
            setLoading(false);
            toast.error("Errore nell'aggiornamento della disponibilità!");
        }
    }
    
    useEffect(() => {
        if(!giornoCalendario) {
            getCalendario(giorno);
            getCalendarioMensile(giorno);
        }

    });

    useEffect(() => {
        const scrollToElement = () => {
            const container = document.querySelector('.slot-list');
            const targetElement = container?.querySelector('.slot-element:nth-child(1)');

            if (targetElement) {
                    const elementPosition = 400//targetElement.getBoundingClientRect().top;
                    
                    container?.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth' // Per uno scroll fluido
                    });
                    

                
            }
        };

        // Esegui lo scrolling al caricamento del componente
        scrollToElement();
        setScroll(false);
    }, [scroll]);
    
    
    return (
        <>
            <div className="barra-superiore">
                <h3>Libera o blocca slot di disponibilità massivamente</h3>
                <div className="riga">
                    {props.actionDateStart && 
                        <div className="date-container">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                                <DateTimePicker  
                                    label="Data Inizio"
                                    minDate={oggi}
                                    maxDate={oggi.add(1, 'year')}
                                    value={props.actionDateStart} 
                                    onChange={(newValue) => changeDataStart(newValue)}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </LocalizationProvider>
                        </div>}
                    {props.actionDateEnd &&
                            <div className="date-container">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                                    <DateTimePicker disablePast 
                                                label="Data Fine"
                                                value={props.actionDateEnd} 
                                                minDate={props.actionDateStart}
                                                maxDate={oggi.add(1, 'year')}
                                                onChange={(newValue) => changeDataEnd(newValue)}
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                    />
                                </LocalizationProvider>
                            </div>
                    }
                    <Button className="bottone-verde mass-action" variant="contained" onClick={liberaButton}>Libera</Button>
                    <Button className="bottone-rosso mass-action" variant="contained" onClick={bloccaButton}>Blocca</Button>

                </div>
            </div>
            <div className="contenuto-esterno-pagina">
                <div className="sidebar-left">
                    <LocalizationProvider  dateAdapter={AdapterDayjs} adapterLocale="it">
                        <DateCalendar 
                            views={['day', 'month']} // Questo limita le viste a giorno e mese, escludendo l'anno
                            value={giorno} 
                            disablePast
                            minDate={oggi}
                            maxDate={oggi.add(1, 'year')}
                            onMonthChange={getCalendarioMensile}
                            onChange={(newValue) => cambiaGiorno(newValue!)}
                            slots={{
                                day: BadgeGiorno,
                            }}
                            slotProps={{
                                day: {
                                    highlightedDays: giorniOccupati,
                                    freeDays: giorniConSlotLiberi,
                                } as any,
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div className="main-column">
                    <div className="flex-container">
                        <div className="slot-container">
                            <div className="slot-header">{giorno.locale('it').format('dddd DD MMMM')}</div>
                            <div className="slot-list">
                                {creaSlotList()}
                            </div>
                        </div>
                        <div className="button-container">
                            <Button className="bottone-verde" variant="contained" onClick={ripetiButton}>Ripeti ogni 7 giorni</Button>
                            <Button className="bottone-rosso" variant="contained" onClick={bloccaGiornoButton}>Blocca tutto il giorno</Button>
                        </div>
                    </div>
                </div>
                <LoadingView visible={loading}/>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );
}

export default CalendarioView;