import { useEffect, useState } from "react";
import { Utente } from "../../models/Utente";
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ApiUtentiAdmin from '../../api/UtentiAdmin';
import Cookies from "js-cookie";
import { cloneDeep, set } from "lodash";
import GiornoCalendarioUtils from "../../utils/GiornoCalendarioUtils";
import ButtonMenu from "../../components/generics/ButtonMenu";
import '../../style/stylePages/admin/UtentiAdmin.css';
import { ToastContainer, toast } from "react-toastify";
import LavoraDate from "../../utils/LavoraDate";
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';



function UtentiAdmin() {
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta
    const [utente, setUtente] = useState<any>();
    const [servizi, setUtenti] = useState<Utente[]>();
    const [utenteSelezionato, setUtenteSelezionato] = useState<Utente>();
    const [filtri, setFiltri] = useState({idInfermiere: undefined, nome: undefined, cognome: undefined, email: undefined});
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);

    const location = useLocation()
    const navigate = useNavigate();

    const getUtenti = async (page?: number) => {
        let data = await ApiUtentiAdmin.getElencoUtenti(filtri, page, 11);
        if(data) {
            setUtenti(data.docs);
            setTotalPages(data.totalPages);
        }
        
    }

    const deleteUtente = async (utente: Utente) => {
        try {
            //const data = await ApiUtentiAdmin.deleteUtenteInfermiere(utente);
            setUtenteSelezionato(undefined);
            setUtenti(undefined);
            toast.success("Utente cancellato con successo");
        } catch (err: any) {
            toast.error(err);
        }
        
    }

    const openDialogFunc = (utente?: Utente) => {
        if(utente) {
            setUtenteSelezionato(utente);
            navigate(`/utente?id=${utente?._id}`);
        }
    }

    const closeDialogAndRefresh = () => {
        setUtenti(undefined);
        setUtenteSelezionato(undefined);
        setOpenDialog(false);
    }

    const closeDialog = () => {
        setUtenteSelezionato(undefined);
        setOpenDialog(false);
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = cloneDeep(filtri);
        set(f, field, event.target.value);
        setFiltri(f);
        setUtenti(undefined);
    };

    //Paginazione
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
        getUtenti(newPage);
    }

    useEffect(() => {
        if(!utente) {
            let authCookie = Cookies.get('auth');
            let user = {}
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;
                setUtente(user);
            }
        }
        else if(!servizi) {
            getUtenti();
        }
    });
    
    return (
        <div className="page-template">
            <div className='main-content'>
                <h2>Utenti</h2>
                <div className="filtri" style={{marginTop: 50}}>
                    <h3 style={{marginTop: 13}}>Filtri: </h3>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.nome || ''}
                            onChange={handleChange('nome')}
                            label="Nome" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.cognome || ''}
                            onChange={handleChange('cognome')}
                            label="Cognome" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.email || ''}
                            onChange={handleChange('email')}
                            label="Email" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className="tabella-servizi" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Ruolo</TableCell>
                            <TableCell>Verificato</TableCell>
                            <TableCell>Attivo</TableCell>
                            <TableCell>Autorizzato</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {servizi?.map((row: Utente) => (
                            <TableRow
                                hover
                                key={row._id}
                                className="table-row"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell onClick={() => openDialogFunc(row)}>{row._id}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.email}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.nome}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.cognome}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.ruolo}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)} className={row.verificatoUtente ? "green-label" : "red-label"}>
                                    {row.verificatoUtente ? "Verificato" : "Non verificato"}
                                </TableCell>
                                <TableCell onClick={() => openDialogFunc(row)} className={row.autorizzatoInfermiere ? "green-label" : "red-label"}>
                                    {row.autorizzatoInfermiere ? "Attivo" : "Non attivo"}
                                </TableCell>
                                <TableCell onClick={() => openDialogFunc(row)} className={row.visibileInfermiere ? "green-label" : "red-label"}>
                                    {row.visibileInfermiere ? "Autorizzato" : "Non autorizzato"}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack spacing={2} className='paginazione'>
                    <Pagination count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
                </Stack>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>

    );
}

export default UtentiAdmin;