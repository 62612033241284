import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";
import { Struttura } from "../models/Struttura";

export default class Strutture { 

    static async getElencoStrutture() {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/strutture/attivi');
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    //admin
    static async getElencoStruttureAdmin(filtri?: any, page: number = 1, limit: number = 10) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let filters = "";
        let fields = "";

        if(filtri) {
            if(filtri.nome) {
                filters += `filter=${filtri.nome}`;
                fields += `fields=nome`;
            }
        }

        try {
            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/strutture?page=${page}&limit=${limit}&${filters}&${fields}`, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    static async addStruttura(struttura: Struttura) {
        const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const response = await Axios.post(Constants.apiWebService.baseUrl + '/strutture', struttura, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
      }
    
      static async updateStruttura(struttura: Struttura) {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const response = await Axios.patch(Constants.apiWebService.baseUrl + '/strutture/' + struttura._id, struttura, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
      }

}