import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import ApiPrenotazioni from '../api/Prenotazioni';
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { Prenotazione } from "../models/Prenotazione";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import TabPrenotazioni from "../components/prenotazioni/TabPrenotazioni";
import Constants from "../utils/Constants";


function Prenotazioni() {
    const [loading, setLoading] = useState(false);
    const [utente, setUtente] = useState<any>();
    const [prenotazioneSelezionata, setPrenotazioneSelezionata] = useState<Prenotazione>();
    const [rowsPerPage, setRowsPerPage] = useState(10);  //Righe per pagina: Valore statico deciso qui!!!
    const [tabValue, setTabValue] = useState<Number>(0);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const location = useLocation()
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);


    const getPrenotazioni = async (idInfermiere: string, tipo: string, pagina: number) => {
        let data;
        if(tipo === "precedenti")
            data = await ApiPrenotazioni.getElencoPrenotazioniInfermierePassate(idInfermiere, pagina, rowsPerPage);
        else
            data = await ApiPrenotazioni.getElencoPrenotazioniInfermiereFuture(idInfermiere, pagina, rowsPerPage);

        let prenotazioni = {docs: [], totalPages: 1};
        if(data) {
            prenotazioni.docs = data.docs;
            prenotazioni.totalPages = data.totalPages;
        }

        return prenotazioni;
    }


    const openDialogFunc = (prenotazione?: Prenotazione) => {
        if(prenotazione) {
            setPrenotazioneSelezionata(prenotazione);
            navigate(`/prenotazione?id=${prenotazione?._id}&t=${tabValue}`);
        }
    }

    const handleChange = (event: any, newValue: Number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if(!utente) {
            const id = queryParameters.get("t");
            if(id)
                setTabValue(parseInt(id));

            let authCookie = Cookies.get('auth');
            let user: any;
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;

                if(user.ruolo === "admin")
                    window.location.replace(`${Constants.backoffice.url!}/prenotazioni-admin?t=${parseInt(id || "0")}`);

                setUtente(user);
            }
        }
    });

    
    return (
        <div className="page-template">
            <div className='main-content' style={{ visibility: !loading ? 'visible': 'hidden'}}>
                <div className="titolo-pagina">Le prenotazioni dei tuoi pazienti</div>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Prenotazioni future" />
                    <Tab label="Prenotazioni eseguite" />
                </Tabs>
                <TabPrenotazioni 
                    value={tabValue} 
                    index={0} 
                    getPrenotazioni={getPrenotazioni}
                    openDialogFunc={openDialogFunc} />
                <TabPrenotazioni 
                    value={tabValue} 
                    index={1} 
                    getPrenotazioni={getPrenotazioni}
                    openDialogFunc={openDialogFunc} />
                
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>

    );
}

export default Prenotazioni;