import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import LoadingView from "../generics/LoadingView";
import { Struttura } from "../../models/Struttura";
import ApiStrutture from "../../api/Strutture";
import { cloneDeep, set } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import '../../style/styleComponents/DialogServizioInfermiere.css';


interface Giorno {
    nome: string;
    val: number
}

interface ComponentProps {
    open?: boolean;
    onClose?(open:boolean): void;
    onSave?(open:boolean): void;
    struttura?: Struttura;
}
export type Props = ComponentProps;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  

function BootstrapDialogTitle(props: DialogTitleProps) {
const { children, onClose, ...other } = props;


return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
        </IconButton>
    ) : null}
    </DialogTitle>
);
}


function DialogStrutturaAdmin(props: Props) {

    const [loading, setLoading] = useState(false);
    const [strutturaSelezionato, setStrutturaSelezionato] = useState<Struttura>();
    const [editMode, setEditMode] = useState(false);
    


    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const serv = cloneDeep(strutturaSelezionato);
        if(serv) {
            let value : any = event.target.value;
            if(field === "prezzoMin" || field === "prezzoMin")
                value = parseFloat(value);


            set(serv, field, value);
            setStrutturaSelezionato(serv);
        }
      };

    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        const serv = cloneDeep(strutturaSelezionato);
        if(serv) {
          set(serv, 'tipologia', parseInt(event.target.value?.toString() || "0"));
          setStrutturaSelezionato(serv);
        }
    }

    const handleStatoChange = (event: SelectChangeEvent<number>) => {
        const serv = cloneDeep(strutturaSelezionato);
        if(serv) {
          set(serv, 'attivo', event.target.value === 1);
          setStrutturaSelezionato(serv);
        }
    }
    
    const handleClose = () => {
        setStrutturaSelezionato(undefined);
        props.onClose!(false);
    };


    const handleSalvaButton = async () => {
        setLoading(true);
        if(strutturaSelezionato) {
            if(editMode) {
                try {
                    await ApiStrutture.updateStruttura(strutturaSelezionato);
                    toast.success('Struttura aggiornato con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            } else {
                try {
                    await ApiStrutture.addStruttura(strutturaSelezionato);
                    toast.success('Struttura aggiunto con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            }
        }
        setStrutturaSelezionato(undefined);
        props.onSave!(false);
        setLoading(false);
    }

    useEffect(() => {
        if(!strutturaSelezionato) {
            if(!props.struttura) {
                setStrutturaSelezionato({nome: "",
                                        attivo: true});
                setEditMode(false);
            } else {
                setStrutturaSelezionato(props.struttura);
                setEditMode(true);
            }
        }
    })

    useEffect(() => {
        if(props.struttura) {
            setStrutturaSelezionato(props.struttura);
            setEditMode(true);
        }
    }, [props.struttura])

    return (
        <div>
        <BootstrapDialog
            className="dialog-struttura-infermiere"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open!}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Configura il struttura
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <div className="riga-due-colonne">
                    <FormControl className='form-field' variant="outlined" hidden={!editMode}>
                        <FormHelperText>Id Struttura</FormHelperText>
                        
                        <OutlinedInput
                            disabled
                            value={strutturaSelezionato?._id || ''}
                            />
                    </FormControl>
                    <FormControl className='form-field' variant="outlined" hidden={!editMode}>
                        <FormHelperText>Stato</FormHelperText>
                        <Select
                            value={strutturaSelezionato?.attivo ? 1 : 0 || 0}
                            onChange={handleStatoChange}
                            >
                                <MenuItem value={0}>Non attivo</MenuItem>
                                <MenuItem value={1}>Attivo</MenuItem>
                            </Select>
                    </FormControl>
                </div>

                <div className="riga">
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Nome</FormHelperText>
                            <OutlinedInput
                                value={strutturaSelezionato?.nome || ""}
                                onChange={handleChange('nome')} 
                            />
                        </FormControl>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSalvaButton} disabled={false /*slotOrarioSelezionato ? false : true*/}>Salva</Button>
            </DialogActions>
        </BootstrapDialog>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />

        <LoadingView
                visible={loading}
        />

        </div>
    );
}
export default DialogStrutturaAdmin;