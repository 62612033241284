import { GiornoCalendario } from "../models/GiornoCalendario";

export default class GiornoCalendarioUtils {

    //Controlla se negli slot c'è almeno una prenotazione
    static esistePrenotazione (giorno: GiornoCalendario) {
        if(giorno.slots) {
            for(let i = 0; i < giorno.slots?.length; i++) {
                let slot = giorno.slots[i];
                if(slot.prenotazioni && slot.prenotazioni.length > 0) {
                    return true;
                }
            }
        }   
        return false;
    }

    static convertiGiorno (giorno: number) {
        var weekday=new Array(7);
        weekday[0]="Domenica";
        weekday[1]="Lunedì";
        weekday[2]="Martedì";
        weekday[3]="Mercoledì";
        weekday[4]="Giovedì";
        weekday[5]="Venerdì";
        weekday[6]="Sabato";
        return weekday[giorno];
    }

    static creaElencoGiorni (giorni?: number[]) {
        const res = [];
        if(giorni) {
            for(let i = 0; i < giorni.length; i++) {
                res.push({
                    nome: this.convertiGiorno(giorni[i]),
                    val: giorni[i]
                })
            }
        }
        return res;
    }

    static creaElencoOrari() {
        const res = [];
        for(let i = 0; i < 24; i++) {
            for(let j = 0; j < 60; j += 15) {
                let ora = i.toString().padStart(2, '0');
                let minuto = j.toString().padStart(2, '0');
                res.push(ora + ":" + minuto);
            }
        }
        return res;
    }
}