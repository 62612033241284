import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Cookies from 'js-cookie';

export default class Auth {

  static getToken = () => {
    let authCookie = Cookies.get('auth');
    if(authCookie) {
        let ck = JSON.parse(authCookie);
        if(ck)
          return ck.token;
    }
    throw new Error("Not logged in");
  }

  static async ChangePassword(oldPassword: string, newPassword: string) {
    try {
        const token = Auth.getToken();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const response = await Axios.post(Constants.apiWebService.baseUrl + '/changePassword', {
            "password": oldPassword,
            "newPassword": newPassword,
        }, config);
            
        return response.data;

    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }
}